import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './header/header.component';
import { MainContentComponent } from './main-content/main-content.component';
import { IndividualComponent } from './login/individual/individual.component';
import { CorporateComponent } from './login/corporate/corporate.component';
import { SCorporateComponent } from './signup/s-corporate/s-corporate.component';
import { SIndividualComponent } from './signup/s-individual/s-individual.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './material/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { ListProductComponent } from './list-product/list-product.component';
import { CartComponent } from './cart/cart.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { ProfileComponent } from './profile/profile.component';
import { QuotationComponent } from './quotation/quotation.component';
import { PindexComponent } from './preva/pindex/pindex.component';
import { PcontactComponent } from './preva/pcontact/pcontact.component';
import { PcoeComponent } from './preva/pcoe/pcoe.component';
import { PcareerComponent } from './preva/pcareer/pcareer.component';
import { PiotplatformComponent } from './preva/piotplatform/piotplatform.component';
import { PteamComponent } from './preva/pteam/pteam.component';
import { PthankyouComponent } from './preva/pthankyou/pthankyou.component';
import { PaboutComponent } from './preva/pabout/pabout.component';
import { PsolutionComponent } from './preva/psolution/psolution.component';
import { AssetManagementComponent } from './solutions/asset-management/asset-management.component';
import { EventManagementComponent } from './solutions/event-management/event-management.component';
import { HealthCareManagementComponent } from './solutions/health-care-management/health-care-management.component';
import { LibraryManagementComponent } from './solutions/library-management/library-management.component';
import { TrackingManagementComponent } from './solutions/tracking-management/tracking-management.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MainContentComponent,
    IndividualComponent,
    CorporateComponent,
    SCorporateComponent,
    SIndividualComponent,
    ListProductComponent,
    ProductDetailsComponent,
    CartComponent,
    ProfileComponent,
    QuotationComponent,
    PindexComponent,
    PcontactComponent,
    PcoeComponent,
    PcareerComponent,
    PiotplatformComponent,
    PteamComponent,
    PthankyouComponent,
    PaboutComponent,
    PsolutionComponent,
    AssetManagementComponent,
    EventManagementComponent,
    HealthCareManagementComponent,
    LibraryManagementComponent,
    TrackingManagementComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MaterialModule,
    ReactiveFormsModule,
    HttpClientModule,
  
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
