import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  products: any[] = [
    {
      size:"M",
      color:"Gray",
      id: 368944156,
      name: 'FADED POPLIN SHIRT',
      price: '₹ 5,950.00',
      imageUrl: 'https://static.zara.net/assets/public/387f/3a88/d82045a5bbb1/0d648cada0db/06085418529-a1/06085418529-a1.jpg?ts=1719832138674&w=182',
      url: 'https://www.zara.com/in/en/faded-poplin-shirt-p06085418.html'
    },
    {
      size:"M",
      color:"Gray",
      id: 368704920,
      name: 'LINEN - SILK BLEND SHIRT',
      price: '₹ 7,550.00',
      imageUrl: 'https://static.zara.net/assets/public/aec3/3ff1/28d64797a7f1/e41ef44f9305/05344305981-a1/05344305981-a1.jpg?ts=1720427419500&w=182',
      url: 'https://www.zara.com/in/en/linen---silk-blend-shirt-p05344305.html'
    },
    {
      size:"M",
      color:"Gray",
      id: 370432114,
      name: 'LEATHER LOAFERS',
      price: '₹ 8,990.00',
      imageUrl: 'https://static.zara.net/assets/public/17a6/0cbf/f2a86b6dd7b4/84cf056ddf11/07707764600-a1/07707764600-a1.jpg?ts=1720428136513&w=182',
      url: 'https://www.zara.com/in/en/leather-loafers-p07707764.html'
    },
    {
      
      size:"M",
      color:"Gray",
      id: 370432114,
      name: 'TEXTURED BLAZER',
      price: '₹ 11,990.00',
      imageUrl: 'https://static.zara.net/assets/public/5c6b/0c9a/18e3457d95f0/08b46ef4474b/08162665182-a1/08162665182-a1.jpg?ts=1720428167897&w=182',
      url: 'https://www.zara.com/in/en/textured-blazer-p08162665.html'
    },
    {
      size:"M",
      color:"Gray",
      id: 369458306,
      name: 'DENIM JACKET',
      price: '₹ 6,950.00',
      imageUrl: 'https://static.zara.net/assets/public/dc91/df2b/f847372aa3fc/05e0d4e0265d/09374706401-a1/09374706401-a1.jpg?ts=1720428191246&w=182',
      url: 'https://www.zara.com/in/en/denim-jacket-p09374706.html'
    },
    {
      size:"M",
      color:"Gray",
      id: 369387258,
      name: 'COTTON T-SHIRT',
      price: '₹ 2,490.00',
      imageUrl: 'https://static.zara.net/assets/public/08bb/2a7a/78dd6c0c65da/0c946cb16dd2/05131917600-a1/05131917600-a1.jpg?ts=1720428230597&w=182',
      url: 'https://www.zara.com/in/en/cotton-t-shirt-p05131917.html'
    },
    {
      size:"M",
      color:"Gray",
      id: 369932684,
      name: 'WOOL COAT',
      price: '₹ 14,990.00',
      imageUrl: 'https://static.zara.net/assets/public/0b8d/0d67/fec09a52d5e4/07b20a5c0d1e/09276941807-a1/09276941807-a1.jpg?ts=1720428267544&w=182',
      url: 'https://www.zara.com/in/en/wool-coat-p09276941.html'
    },
    {
      size:"M",
      color:"Gray",
      id: 369446980,
      name: 'BLOUSE WITH RUFFLES',
      price: '₹ 4,990.00',
      imageUrl: 'https://static.zara.net/assets/public/8aef/0df1/f1dfe9476b1d/07df25fd9a4f/05402031830-a1/05402031830-a1.jpg?ts=1720428293322&w=182',
      url: 'https://www.zara.com/in/en/blouse-with-ruffles-p05402031.html'
    },
    {
      size:"M",
      color:"Gray",
      id: 368774781,
      name: 'TROUSER WITH BELT',
      price: '₹ 5,550.00',
      imageUrl: 'https://static.zara.net/assets/public/0be5/0db4/8cdd8e8fcf08/0d8dbe7f84c0/09041575900-a1/09041575900-a1.jpg?ts=1720428325781&w=182',
      url: 'https://www.zara.com/in/en/trouser-with-belt-p09041575.html'
    },
    {
      size:"M",
      color:"Gray",
      id: 368707689,
      name: 'LEATHER BELT',
      price: '₹ 2,990.00',
      imageUrl: 'https://static.zara.net/assets/public/5c2e/0d3a/9d5f044ddc47/0d781f3b5d82/05400583100-a1/05400583100-a1.jpg?ts=1720428352360&w=182',
      url: 'https://www.zara.com/in/en/leather-belt-p05400583.html'
    },
    {
      size:"M",
      color:"Gray",
      id: 369392984,
      name: 'SILK SCARF',
      price: '₹ 3,550.00',
      imageUrl: 'https://static.zara.net/assets/public/0e7e/0d21/929b918b4a8e/0d9485d58d91/07403904301-a1/07403904301-a1.jpg?ts=1720428383790&w=182',
      url: 'https://www.zara.com/in/en/silk-scarf-p07403904.html'
    }
  ];
  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
  }
  onClick(data: any) {
    // Handle click event with data
    console.log('Span clicked with data:', data);
    this.router.navigate(['/cart'], { queryParams: data });
  }
}
