<div style="height: 7vh;">

</div>
<div fxLayout="column" style="height: 85vh;background-color: transparent;" fxHide.gt-xs>
    <mat-card class="product-detail-card" >
        <mat-card-content>
            <div class="product-detail-card__product-images">
                <ng-container *ngFor="let source of images">
                    <img *ngIf="source.src" [src]="source.src" class="product-image">
                </ng-container>
            </div>
        </mat-card-content>
    </mat-card>
    <mat-card class="sticky-card" style="padding: 20px;" >
    
        <mat-card-content >
    
            <div fxLayout="column" fxLayoutAlign="start stretch" >
                <div>
                    <!-- FEW ITEMS LEFT -->
                     {{this.product_detail}}
                </div>
                <div style="font-size: 16px;">SHIRT</div>
                <div>₹ 2,950.00</div>
                <div>MRP incl. of all taxes</div>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <button fxFlex="80" style="border: 1px solid black;" mat-button (click)="onClick({ item: 'product', label: 'product' })">ADD</button>
            <button fxFlex="20" style="border: 1px solid black;" mat-button><mat-icon style="font-size: 19px;">bookmark_border</mat-icon></button>
        </mat-card-actions>
    </mat-card>

</div>

<!-- desktop -->
<div fxLayout="row" style="height: 85vh;background-color: transparent;" fxHide.lt-md>
    <div fxFlex="10" style="background-color: transparent;height: 93vh;">
        
    </div>

    <div fxFlex="55"  fxLayout="row" fxLayoutAlign="start end">
        <div fxLayout="row" fxLayoutAlign="start end" style="width: 100%;">
            <mat-card fxLayout="column" fxLayoutAlign="center center" style="width: 20vw;border: 1px solid black;padding: 20px;">

                <mat-accordion>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                            COMPOSITION, CARE & ORIGIN
                        </mat-panel-title>
          
                      </mat-expansion-panel-header>
                      <div class="scroll-exp" style="height: 400px;overflow: scroll;">
                        <strong>COMPOSITION</strong><br>
                        We work with monitoring programmes to ensure compliance with our social, environmental, and health and safety standards for our products. To assess compliance, we have developed a programme of audits and continuous improvement plans.<br><br>
                      
                        <strong>OUTER SHELL</strong><br>
                        60% cotton<br>
                        40% polyester<br>
                        Which contains at least:<br><br>
                      
                        <strong>OUTER SHELL</strong><br>
                        40% RCS certified recycled polyester<br>
                        60% OCS certified organically grown cotton<br><br>
                      
                        <strong>CERTIFIED MATERIALS</strong><br>
                        <strong>RCS CERTIFIED RECYCLED POLYESTER</strong><br>
                        Nowadays, recycled polyester is mainly made from PET plastic waste. This is a type of plastic that is widely used in a variety of items, such as plastic bottles. Using recycled materials helps limit the production of virgin polyester fibre. It is certified to the Recycled Claim Standard (RCS), which verifies the recycled content and tracks it from source to final product.<br>
                        Certified by Intertek 193341<br>
                        <a href="#">More information</a><br><br>
                      
                        <strong>OCS CERTIFIED ORGANICALLY GROWN COTTON</strong><br>
                        This fibre is produced using no artificial fertilisers or pesticides and is grown from seeds that have not been genetically modified. We are currently working with the Organic Content Standard (OCS) that monitors the process from the source to the end product.<br>
                        Certified by Intertek 193341<br>
                        <a href="#">More information</a><br><br>
                      
                        <strong>CARE</strong><br>
                        Caring for your clothes is caring for the environment.<br>
                        Lower temperature washes and delicate spin cycles are gentler on garments and help to protect the colour, shape, and structure of the fabric. Furthermore, they reduce the amount of energy used in care processes.<br><br>
                      
                        <strong>Clothing Care Guide</strong><br>
                        <ul>
                          <li>Machine wash at max. 30ºC/86ºF with short spin cycle</li>
                          <li>Do not use bleach</li>
                          <li>Iron at a maximum of 110ºC/230ºF</li>
                          <li>Dry clean with tetrachloroethylene</li>
                          <li>Tumble dry low</li>
                        </ul><br>
                      
                        <strong>ORIGIN</strong><br>
                        We work with our suppliers, workers, unions, and international organisations to develop a supply chain in which human rights are respected and promoted, contributing to the United Nations Sustainable Development Goals.<br>
                        Thanks to the collaboration with our suppliers, we work to know the facilities and processes used to manufacture our products in order to understand their traceability.<br>
                        Made in Bangladesh
                      </div>
                      
                    </mat-expansion-panel>
                  
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                            SHIPPING, EXCHANGES AND RETURNS
                        </mat-panel-title>
                   
                      </mat-expansion-panel-header>

                      <div class="scroll-exp" style="height: 400px;overflow: scroll;">
                        <strong>SHIPPING</strong><br>
                        <strong>SHIPPING TO A ZARA STORE - FREE</strong><br>
                        Delivery in 3-5 working days.<br><br>
                      
                        <strong>HOME DELIVERY</strong><br>
                        Delivery in 2-8 working days - ₹ 290.00<br>
                        Shipping will be free for orders over ₹ 2,990, only for non-discounted items.<br><br>
                      
                        <strong>EXCHANGES AND RETURNS</strong><br>
                        You have 30 days from the shipping date to return your purchase from Zara.com.<br><br>
                      
                        <strong>RETURNS TO A ZARA STORE - FREE</strong><br>
                        <strong>HOME COLLECTION - ₹ 100</strong><br>
                        The cost of the return applies to each request and will be deducted from the refund amount.<br><br>
                      
                        For more information, we suggest you go to the Help section.
                      </div>
                      
                    </mat-expansion-panel>
                  </mat-accordion>
                  

            </mat-card>
        </div>
        <mat-card style="margin-right: 0px; height: 75vh; width: 40vw; padding: 0px;margin-top: 10vh;" fxHide.lt-md>
            <mat-card-content style="background-color: transparent; height: 100%; width: 100%; padding: 0px; overflow: hidden;">
              <div class="scroll-container" style="background-color: transparent; height: 100%; width: 100%; position: relative;">
                <ng-container *ngFor="let source of images">
                  <img style="width: 100%; height: 100%; object-fit: cover;" *ngIf="source.src" [src]="source.src" class="product-image">
                </ng-container>
              </div>
            </mat-card-content>
          </mat-card>
          
          

    </div>

    <div fxFlex="25" fxLayout="row" fxLayoutAlign="end start">
        <mat-card style="margin-right: 0px; height: 75vh; width: 30vw; padding: 0px;margin-top: 10vh;">
            <div fxLayout="row" fxLayoutAlign="center center" >
                <div fxLayout="column" fxLayoutAlign="start center" style="border: 1px solid black;padding: 0px;width: 20vw;">
                    <div fxLayout="row" fxFlex="45" style="padding: 20px;">
                        <div fxLayout="column" fxFlex="90" fxLayoutGap="20px">
                            <div>
                                <div class="item-header">
                                    FEW ITEMS LEFT
                                </div>
                                <div class="item-title">
                                    <!-- PRINTED EASY CARE SHIRT -->
                                     {{this.product_detail[0]['Item_Type']}}
                                </div>
                                <div class="item-title">
                                    <!-- PRINTED EASY CARE SHIRT -->
                                     {{this.product_detail[0]['Item_Name']}}
                                </div>
                                <div class="item-title">
                                    <!-- PRINTED EASY CARE SHIRT -->
                                     {{this.product_detail[0]['itemtype_color']}}
                                </div>
                                <div class="item-title">
                                    <!-- PRINTED EASY CARE SHIRT -->
                                     {{this.product_detail[0]['Item_Desc']}}
                                </div>
                                <div class="item-title">
                                    <!-- PRINTED EASY CARE SHIRT -->
                                     {{this.product_detail[0]['Supplier']}}
                                </div>
                                <div class="item-price" *ngIf="this.product_detail[0]['discount_price']">
                           
                                    ₹ {{this.product_detail[0]['discount_price']}}
                                </div>
                                <div class="item-price" *ngIf="!this.product_detail[0]['discount_price']">

                                    ₹ {{this.product_detail[0]['Selling_Price']}}
                                </div>
                                <div class="item-mrp">
                                    MRP incl. of all taxes
                                </div>
                            </div>

                            <!-- <div class="item-description">
                                Shirt made of fabric with easy care and everyday maintenance texture.
                            </div>
                            <div class="item-details">
                                Regular-fit spread collar shirt featuring long sleeves with buttoned cuffs. Button-up front.
                            </div> -->
                            <div class="item-availability">
                                Check in-store availability
                            </div>
                            <div class="item-shipping">
                                SHIPPING, EXCHANGES AND RETURNS
                            </div>
                        </div>
                        <div fxFlex="10">
                            <mat-icon style="font-size: 19px;">bookmark_border</mat-icon> 
                        </div>
                    </div>
                    <!-- <div fxFlex="25" class="button-container">
                        <div fxLayout="column" fxLayoutGap="20px">
                          <ng-container *ngFor="let row of this.product_detail">
                            <div fxLayout="row" fxLayoutGap="20px">
                              <ng-container *ngFor="let button of row">
                                <button mat-stroked-button style="width: 100%;">{{button}}</button>
                              </ng-container>
                            </div>
                          </ng-container>
                        </div>
                      </div> -->
                    <div fxFlex="25" class="button-container">
                        <div fxLayout="column" fxLayoutGap="20px">
                            <ng-container *ngFor="let row of this.buttonRows">
                                <div fxLayout="row" fxLayoutGap="20px">
                                    <ng-container *ngFor="let button of row">
                                        <button mat-stroked-button style="width: 100%;">{{ button }}</button>
                                      </ng-container>
                                </div>
                            </ng-container>
                            
                            <!-- <div fxLayout="row" fxLayoutGap="20px">
                                <button mat-stroked-button style="width: 100%;">L</button>
                                <button mat-stroked-button style="width: 100%;">XL</button>
                            </div> -->
                        </div>
                    </div>
                    
                    <div fxFlex="10" style="border-top: 1px solid black;width: 100%;">
                        <button mat-button style="width: 100%;" (click)="onClick({ item: 'product', label: 'product' })">ADD</button>
                    </div>
                </div>
            </div>

        </mat-card>
        
    </div>

    <div fxFlex="10" style="background-color: transparent;height: 93vh;">
        
    </div>

</div>