import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EcomService } from '../ecom.service';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {
  images = [
    // {
    //   src: 'assets/images/product1.jpg',
    //   alt: 'Product Image 1',
    //   paddingBottom: '75%',
    //   sources: [
    //     { sizes: '100vw', srcset: 'assets/images/product1.jpg' }
    //   ]
    // },
    // {
    //   src: 'assets/images/product2.jpg',
    //   alt: 'Product Image 2',
    //   paddingBottom: '75%',
    //   sources: [
    //     { sizes: '100vw', srcset: 'assets/images/product2.jpg' }
    //   ]
    // },
    // {
    //   src: 'assets/images/product3.jpg',
    //   alt: 'Product Image 3',
    //   paddingBottom: '75%',
    //   sources: [
    //     { sizes: '100vw', srcset: 'assets/images/product3.jpg' }
    //   ]
    // }
    // Add more images as needed
  ];
  product_detail: any;
  buttonRows: string[][] = [];
  
  constructor(private route: ActivatedRoute, private router: Router, private es:EcomService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      console.log(JSON.parse(params['products']))
      
      this.product_detail = JSON.parse(params['products']);
      this.images.push(
              {
                src: this.product_detail[0]['image_left']
              }
      )
      this.images.push(
        {
          src: this.product_detail[0]['image_right']
        }
      )
      this.images.push(
        {
          src: this.product_detail[0]['image_top']
        }
      )
      this.images.push(
        {
          src: this.product_detail[0]['image_bottom']
        }
      )
      this.images.push(
        {
          src: this.product_detail[0]['image_front']
        }
      )
      this.images.push(
        {
          src: this.product_detail[0]['image_back']
        }
      )


      console.log(

      )
      
      this.groupButtonsInRows();
      // list_product_details
      console.log(this.product_detail[0])
      this.es.list_product_details(this.product_detail[0]['Org_Id'],this.product_detail[0]['Item_Name'], this.product_detail[0]['Item_Type'], this.product_detail[0]['Item_SubType']).subscribe(
        (data:any) => {
          console.log('Products:', data);
          // this.products = data['products']
        },
        (error) => {
          console.error('Error fetching products:', error);
        }
      );
    

    })
  }

  groupButtonsInRows() {
    const sizes: string[] = Array.from(new Set(this.product_detail.map(item => String(item.size)))) as string[];  // Convert to string if necessary
    for (let i = 0; i < sizes.length; i += 2) {
      this.buttonRows.push(sizes.slice(i, i + 2));  // Group sizes in pairs
    }
    console.log(
      this.buttonRows
    )
  }

  onClick(data: any) {
    // Handle click event with data
    console.log('Span clicked with data:', data);
    this.router.navigate(['/cart'], { queryParams: data });
  }
}
