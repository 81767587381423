import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pthankyou',
  templateUrl: './pthankyou.component.html',
  styleUrls: ['./pthankyou.component.scss']
})
export class PthankyouComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
