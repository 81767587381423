<div style="height: 60px;">

</div>
<div class="container">
    <!-- Display Grid for Extra Small Devices (xs) -->
    <div fxHide.lt-md>
        <mat-grid-list cols="6" rowHeight="1:1.5">
            <mat-grid-tile *ngFor="let product of this.similarItems">
                <mat-card fxLayout="column" (click)="onClick(product)" class="example-card" style="padding: 0; height: 100%; width: 100%; display: flex; flex-direction: column;">
                    <div fxFlex="80" style="overflow: hidden;" *ngIf="product.image_front; else noImage">
                        <img fxFlex="100" style="width: 100%; height: auto;" src="{{product[0].image_front}}">
                        <!-- <img fxFlex="100" style="width: 100%; height: auto;" src="https://static.zara.net/assets/public/387f/3a88/d82045a5bbb1/0d648cada0db/06085418529-a1/06085418529-a1.jpg" > -->
                    </div>
                    
                        <ng-template #noImage>
                            <div fxFlex="80" fxLayout="column" fxLayoutAlign="center center" class="no_image">
                                <p>No image available</p>
                            </div>
                            
                        </ng-template>
                    <div fxFlex="20" style="padding: 10px; border-top: 1px solid black;">
                        <div fxLayout="column" fxLayoutAlign="start stretch">
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <div fxFlex="80">
                                    {{ product[0].Item_Name }}
                                </div>
                                <div fxFlex="20" fxLayoutAlign="end center">
                                    <mat-icon style="font-size: 19px;">bookmark_border</mat-icon>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <div fxFlex="100">
                                    ₹ {{ product[0].Selling_Price }}
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </mat-grid-tile>
        </mat-grid-list>
    </div>

    <!-- Display Grid for Small Devices (gt-xs) -->
    <div fxHide.gt-xs>
        <mat-grid-list cols="2" rowHeight="1:1.5" style="position: static;">

            <mat-grid-tile *ngFor="let product of this.similarItems" >
  
                <mat-card fxLayout="column" (click)="onClick(product)" class="example-card" style="padding: 0; height: 100%; width: 100%; display: flex; flex-direction: column;">
                    <div fxFlex="80" style="overflow: hidden;" *ngIf="product.image_front; else noImage">
                        <img fxFlex="100" style="width: 100%; height: auto;" src="{{product[0].image_front}}">
                        <!-- https://static.zara.net/assets/public/387f/3a88/d82045a5bbb1/0d648cada0db/06085418529-a1/06085418529-a1.jpg" > -->
                    </div>
                    <ng-template #noImage>
                    <div fxFlex="80" fxLayout="column" fxLayoutAlign="center center" class="no_image">
                         <p>No image available</p>
                    </div>
                    </ng-template>
                 
      
                    <div fxFlex="20" style="padding: 10px; border-top: 1px solid black; font-size: 10px;">
                        <div fxLayout="column" fxLayoutAlign="start stretch">
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <div fxFlex="80">
                                    {{ product[0].Item_Name }}
                                </div>
                                <div fxFlex="20" fxLayoutAlign="end center">
                                    <mat-icon style="font-size: 16px;">bookmark_border</mat-icon>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <div fxFlex="100">
                                    ₹ {{ product[0].Selling_Price }}
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
</div>
