<!DOCTYPE html>
<html lang="en">

<head>
  <link rel="icon" href="/assets/resources/images/logo/favicon.ico" type="img" sizes="16x16" />
  <title>COE | Woodsman Smart Retail Solutions LLP</title>
  <meta charset="utf-8" />
  <!-- <meta  -->
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
  <meta name="keywords" content="platform,internet of things,iot,iot-platform,Woodsman,Woodsman Smart Retail Solutions LLP,ios-solutions,industry-solutions,iotapplications,center-of-excellence,business-solutions,value-propostions,Offerings,design-business,
    Student-development,faculty-development,inventions,creative,domain-expertise,smart-city,health-care,Telematics,latest-techonology,expertise,experience-center,Practical,
    handson,transport,identification" />

  <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700" rel="stylesheet" />
  <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css"
    integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossorigin="anonymous" />

  <link rel="stylesheet" href="/assets/resources/css/open-iconic-bootstrap.min.css" />
  <link rel="stylesheet" href="/assets/resources/css/animate.css" />

  <link rel="stylesheet" href="/assets/resources/css/owl.carousel.min.css" />
  <link rel="stylesheet" href="/assets/resources/css/owl.theme.default.min.css" />
  <link rel="stylesheet" href="/assets/resources/css/magnific-popup.css" />

  <link rel="stylesheet" href="/assets/resources/css/aos.css" />

  <link rel="stylesheet" href="/assets/resources/css/ionicons.min.css" />

  <link rel="stylesheet" href="/assets/resources/css/bootstrap-datepicker.css" />
  <link rel="stylesheet" href="/assets/resources/css/jquery.timepicker.css" />

  <link rel="stylesheet" href="/assets/resources/css/flaticon.css" />
  <link rel="stylesheet" href="/assets/resources/css/icomoon.css" />
  <link rel="stylesheet" href="/assets/resources/css/style.css" />
</head>

<body>
  <nav class="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light" id="ftco-navbar">
    <div class="container">
      <a class="navbar-brand" href=""><img src="resources/images/logo/LOGO.png" /></a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav"
        aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="oi oi-menu"></span> Menu
      </button>

      <div class="collapse navbar-collapse" id="ftco-nav">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a href="IOT-platform" class="nav-link">Platform</a>
          </li>
          <li class="nav-item dropdown" (mouseenter)="onMouseEnter($event)" (mouseleave)="onMouseLeave($event)">
            <a class="nav-link" [attr.aria-expanded]="isDropdownOpen" href="solutions">Solutions</a>
            <ul class="dropdown-menu" [class.show]="isDropdownOpen">
              <li>
                <a href="solutions/asset-management">ASSET MANAGEMENT</a>
              </li>
              <div class="dropdown-divider"></div>
              <li>
                <a href="solutions/event-management">EVENT MANAGEMENT</a>
              </li>
              <div class="dropdown-divider"></div>
              <li>
                <a href="solutions/health-care-management">HEALTHCARE MANAGEMENT</a>
              </li>
              <div class="dropdown-divider"></div>
              <li>
                <a href="solutions/library-management">LIBRARY MANAGEMENT</a>
              </li>

              <div class="dropdown-divider"></div>
              <li>
                <a href="solutions/tracking-management">TRACKING MANAGEMENT</a>
              </li>
            </ul>
          </li>
          <li class="nav-item"><a class="nav-link" href="coe">coe</a></li>
          <li class="nav-item">
            <a href="career" class="nav-link">Career</a>
          </li>

          <!--<li class="nav-item"><a href="blog.html" class="nav-link">Blog</a></li>-->
          <li class="nav-item">
            <a href="contact" class="nav-link">Contact</a>
          </li>
          <!--   <li class="nav-item cta"><a href="contact" class="nav-link" data-toggle="modal" data-target="#modalRequest"><span>Get a Quote</span></a></li>-->
        </ul>
      </div>
    </div>
  </nav>
  <!-- END nav -->

  <div class="hero-wrap hero-wrap-2" data-stellar-background-ratio="0.5">
    <!-- <div class="overlay"></div>-->
    <div class="container-fluid">
      <div class="row no-gutters d-flex slider-text align-items-center justify-content-center"
        data-scrollax-parent="true">
        <div class="col-md-6 ftco-animate text-center" data-scrollax=" properties: { translateY: '70%' }">
          <p class="breadcrumbs" data-scrollax="properties: { translateY: '30%', opacity: 1.6 }">
            <span class="mr-2"><a href="">home</a></span><span></span>
          </p>
          <h1 class="mb-3 bread" data-scrollax="properties: { translateY: '30%', opacity: 1.6 }">
            COE
          </h1>
        </div>
      </div>
    </div>
  </div>

  <section class="ftco-section ftco-services">
    <div class="container">
      <div class="heading-section ftco-animate">
        <h2 class="mb-2 text-center">CENTER OF EXCELLENCE</h2>
        <br /><br />
      </div>

      <div class="row">
        <!-- First Column with Text -->
        <div class="col-sm-6 text-left heading ftco-animate">
          <p>
            CoEs are the nerve centres of ideation & research in IoT where
            young minds solve problems across different domains ranging from
            industrial automation to urban sanitation, environmental etc for
            sustainable living. Various Govt initiatives such as Smart cities,
            Skill India, Make in India and Digital India are creating numerous
            opportunities for skilled personnel in emerging technology areas.
          </p>
          <br />
          <p>
            Skill India initiative takes a leap forward in meeting the large
            demand for skilled resources in trending technologies such as IoT.
            To support this initiative Centres of Excellence are being setup
            to bridge the staggering demand supply gap of skilled resources to
            cater to the needs of business. Woodsman has been the baton bearer
            in implementing IoT CoE in India with centres being setup and
            workshops conducted across the nation. This will build and enrich
            the IoT ecosystem from contributing young minds & make them more
            Employable, Research oriented and Entrepreneurial.
          </p>
          <p>
            <i class="fas fa-hand-point-right" style="color: deepskyblue"></i>
            Ideate innovative solutions & implement.
          </p>
          <p>
            <i class="fas fa-hand-point-right" style="color: deepskyblue"></i>
            Orchestrate industry oriented curriculum.
          </p>
          <p>
            <i class="fas fa-hand-point-right" style="color: deepskyblue"></i>
            Transformal technology training.
          </p>
          <p>
            <i class="fas fa-hand-point-right" style="color: deepskyblue"></i>
            Creation of skilled resources.
          </p>
          <p>
            <i class="fas fa-hand-point-right" style="color: deepskyblue"></i>
            Overcome Employment, Research, Entrepreneurial challenges
          </p>
          <p>
            <i class="fas fa-hand-point-right" style="color: deepskyblue"></i>
            Excellence in Industry Institute Interaction
          </p>
          <br />
        </div>

        <!-- Second Column with Image -->
        <div class="col-md-6 text-left heading ftco-animate">
          <img src="resources/images/bg/iot-full.jpg" alt="IoT Full Image" />
          <br /><br />
        </div>
      </div>
      <!-- End of Row -->

      <div class="border-top"></div>

      <!-- Academics Section -->
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12 heading-section ftco-animate text-left">
            <h2 class="mb-2 text-center">ACADEMICS</h2>
            <span class="subheading text-center" style="font-weight: bold; font-size: 17px">IoT Offerings – Student
              Development</span>
            <br />
            <p>-&gt; Comprehensive in-depth workshop on IoT</p>
            <p>-&gt; What is IoT? Why IoT?</p>
            <p>-&gt; Use Cases</p>
            <p>-&gt; Various IoT Technologies</p>
            <p>-&gt; Communication Technologies used in IoT</p>
            <p>-&gt; IoT Platform – Requirements, Challenges & Constraints</p>
            <p>-&gt; Woodsman IoT Platform</p>
            <p>-&gt; Woodsman Solutions</p>
            <p>-&gt; Hands-on sessions on IoT</p>
            <p>
              -&gt; IoT Project Planning & Completion – mandatory to issue
              certificates.
            </p>
          </div>
        </div>
      </div>

      <!-- Faculty Development Section -->
      <div class="container">
        <div class="row">
          <div class="col-md-12 heading-section ftco-animate text-left">
            <span class="subheading text-center" style="font-weight: bold; font-size: 17px">IoT Offerings – Faculty
              Development</span>
            <br />
            <p>-&gt; Comprehensive workshop on IoT for Faculty</p>
            <p>-&gt; IoT technologies overview</p>
            <p>-&gt; Various Communication Technologies for IoT</p>
            <p>
              -&gt; Need and Constraints of an IoT Platform and IoT Platform
              Architecture
            </p>
            <p>-&gt; Embedded-based Solutions</p>
            <p>-&gt; Demos and Practicals</p>
            <p>-&gt; Annual refresher training program</p>
          </div>
        </div>
      </div>
    </div>
    <!-- End of Container -->
  </section>

  <section class="ftco-section-parallax">
    <div class="parallax-img d-flex align-items-center">
      <div class="container">
        <div class="row d-flex justify-content-center">
          <div class="col-md-7 text-center heading-section heading-section-white ftco-animate">
            <!-- <a class="btn btn-primary  px-3 py-2" href="/assets/resources/docs/coe.pdf" download="">Download
              Brochure</a> -->
            <!-- <div class="row d-flex justify-content-center mt-5">
                      <div class="col-md-8">
                        <form action="#" class="subscribe-form">
                          <div class="form-group d-flex">
                            <input type="text" class="form-control" placeholder="Enter email address">
                            <input type="submit" value="Subscribe" class="submit px-3">
                          </div>
                        </form>
                      </div>
                    </div>-->
          </div>
        </div>
      </div>
    </div>
  </section>
  <br />
  <div class="container">
    <div class="row">
      <div class="col-md-12 heading-section ftco-animate text-left">
        <!--<h2 class="mb-2 text-center">ACADEMICS</h2>-->
        <span class="subheading text-center" style="font-weight: bold; font-size: 17px">IoT Offerings - CoE Lab</span>
        <br />
        <p>-&gt; Woodsman IoT Platform Set Up</p>
        <p>-&gt; RESTFul Web Service API for application development</p>
        <p>-&gt; Various Sensors, Devices and Boards as part of lab</p>
        <p>
          -&gt; Woodsman solutions giving ideas on Use Cases: Opportunity to
          innovate and enhance these solutions
        </p>
        <p>-&gt; New Projects, Ideas and Solutions</p>
        <p>-&gt; IoT Certification Program</p>
      </div>
    </div>
  </div>
  <!-- <div class="container">
                  <div class="row justify-content-center mb-5 pb-5">
                    <div class="col-md-8 text-left heading-section ftco-animate">
                      <h2 class="mb-2 text-center">Value Propositoin:</h2>

       <div class="row">
          <div class="col-md-4 d-flex align-self-stretch ftco-animate">
            <div class="media block-6 services d-block text-center">
              <div class="d-flex justify-content-center"><div class="icon"><span class="flaticon-research"></span></div></div>
              <div class="media-body p-2 mt-3">
                <h3 class="heading">Other Business Solutions</h3>
                <p>Asset Management.</p>
                  <p>Smart city.</p>  
                  <p>
                        Health Care.
                  </p>
                  <p>Telematics.</p>
              </div>
            </div>      
          </div>
          <div class="col-md-4 d-flex align-self-stretch ftco-animate">
            <div class="media block-6 services d-block text-center">
              <div class="d-flex justify-content-center"><div class="icon"><span class="flaticon-creativity"></span></div></div>
              <div class="media-body p-2 mt-3">
                <h3 class="heading">IoT CENTRE
                    OF EXCELLENCE</h3>
                <p>Entrepreneurial</p>
                    <p>Design Business Solutions</p>
                        <p>Domain Expertise.</p>
                        <p>Platform Integration</p>
                        <p>Prototype development</p>
                        <p>Employability skills</p>
                        <p>Research Orientation</p>
              </div>
            </div>    
          </div>-->
  <!--<div class="col-md-4 d-flex align-self-stretch ftco-animate">
            <div class="media block-6 services d-block text-center">
              <div class="d-flex justify-content-center"><div class="icon"><span class="flaticon-market"></span></div></div>
              <div class="media-body p-2 mt-3">
                <h3 class="heading">Value proposition</h3>
                <p>Entrepreneurial.</p>
                <p>Design business solutions.</p>
                    <p>Domain expertise.</p>
              </div>
            </div>      
          </div>-->
  <!--- <div class="col-md-4 d-flex align-self-stretch ftco-animate">
            <div class="media block-6 services d-block text-center">
              <div class="d-flex justify-content-center"><div class="icon"><span class="flaticon-research"></span></div></div>
              <div class="media-body p-2 mt-3">
                <h3 class="heading">Other Offerings to Institutions</h3>
                <p>Student Development Program.</p>
                <p>Faculty Development Program.</p>
                
              
              </div>
            </div>      
          </div>-->

  <!--<div class="col-md-4 d-flex align-self-stretch ftco-animate">
            <div class="media block-6 services d-block text-center">
              <div class="d-flex justify-content-center"><div class="icon"><span class="flaticon-market"></span></div></div>
              <div class="media-body p-2 mt-3">
                <h3 class="heading">Audience Analytics</h3>
                <p>Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic.</p>
              </div>
            </div>      
          </div>
        </div>
      </div>-->
  <!-- <div class="container-wrap mt-5">
      	<div class="row d-flex no-gutters">
      		<div class="col-md-6 img ftco-animate" style="background-image: url(images/about.jpg);">
      		</div>
      		<div class="col-md-6 d-flex">
      			<div class="services-wrap">
      				<div class="heading-section mb-5 ftco-animate">
		            <h2 class="mb-2">Our Service Keeps you Happy</h2>
		            <span class="subheading">Search Engine &amp; Social Media Optimization Experts</span>
		          </div>
      				<div class="list-services d-flex ftco-animate">
      					<div class="icon d-flex justify-content-center align-items-center">
      						<span class="icon-pencil"></span>
      					</div>
      					<div class="text">
	      					<h3>Logo Branding</h3>
	      					<p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
      					</div>
      				</div>
      				<div class="list-services d-flex ftco-animate">
      					<div class="icon d-flex justify-content-center align-items-center">
      						<span class="icon-web"></span>
      					</div>
      					<div class="text">
	      					<h3>Development</h3>
	      					<p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
      					</div>
      				</div>
      				<div class="list-services d-flex ftco-animate">
      					<div class="icon d-flex justify-content-center align-items-center">
      						<span class="icon-pie-chart"></span>
      					</div>
      					<div class="text">
	      					<h3>Online Marketing</h3>
	      					<p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
      					</div>
      				</div>
      			</div>
      		</div>
      	</div>-->

  <!--   <div class="container">
        <div class="heading-section mb-5 ftco-animate">
            <h2 class="mb-2 text-center">List Of Workshops Conducted</h2>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Workshop</th>
            <th>Institute</th>
            <th>Location</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>IoT –Awareness session</td>
            <td>SVERI's College of Engineering</td>
            <td>Pandharpur</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>IoT –Workshop</td>
            <td>KIIT</td>
            <td>Bhubaneshwar</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>IoT –Trends</td>
            <td>Cambridge Institute of Technology</td>
            <td>	Bangalore</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>IoT -Workshop +Projects</td>
            <td>BGS Institute of Technology</td>
            <td>Bellur</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>IoT-FDP/SDP</td>
            <td>College of Engg Technology</td>
            <td>Bhubaneshwar</td>
          </tr>
        </tbody>
      
      <tbody>
        <tr>
          <td>IoT-FDP/SDP</td>
          <td>GSSS College for Women</td>
          <td>	Mysore</td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <td>IoT- Workshop</td>
          <td>Veerappa Nisty Engineering College</td>
          <td>Surpur</td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <td>IoT- Workshop</td>
          <td>Eastern College</td>
          <td>Bhubaneshwar</td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <td>IoT-FDP/SDP</td>
          <td>	BKIT</td>
          <td>Bhalki</td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <td>IoT-FDP</td>
          <td>Appa Institute of Technology</td>
          <td>Kalaburagi</td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <td>IoT- Workshop</td>
          <td>St.Francis Institute of Technology</td>
          <td>Mumbai</td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <td>IoT- Workshop</td>
          <td>VTU-Regional Center</td>
          <td>Kalaburagi</td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <td>IoT-FDP/SDP</td>
          <td>Pune Vidhyarthi Griha's College of Engineering and Technology</td>
          <td>	Pune</td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <td>IoT-SDP</td>
          <td>Rajarajeswari College of Engineering</td>
          <td>	Bangalore</td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <td>IoT-SDP/Workshop</td>
          <td>Radhakrishna Institute of Technology and Engineering</td>
          <td>Bhubaneshwar</td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <td>IoT Awareness Session</td>
          <td>Sir M.Visvesvaraya Institute of Technology</td>
          <td>Bangalore</td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <td>IoT-SDP/Workshop</td>
          <td>Bangalore Institute of Technology</td>
          <td>Bangalore</td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <td>IoT-Club Inauguration</td>
          <td>Reva Institute of Technology</td>
          <td>Bangalore</td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <td>IoT Boot Camp</td>
          <td>Vellore Institute of Technology</td>
          <td>	Vellore</td>
        </tr>
      </tbody>
    </table>
    </div>

    </div>-->

    <footer class="ftco-footer ftco-bg-dark ftco-section">
      <div class="container">
        <div class="row mb-5">
          <div class="col-md-4">
            <div class="ftco-footer-widget mb-4">
              <h2 class="ftco-heading-2 text-left">Woodsman</h2>
              <p>
                Woodsman has been established in Dec 2021 with a vision to be
                one of the top 10 IOT Solution Providers in Asia by 2026.
              </p>
            </div>
            <!--  <ul class="ftco-footer-social list-unstyled float-md-left float-lft ">
                <li class="ftco-animate"><a href="#"><span class="icon-twitter"></span></a></li>
                <li class="ftco-animate"><a href="#"><span class="icon-facebook"></span></a></li>
                <li class="ftco-animate"><a href="#"><span class="icon-instagram"></span></a></li>
              </ul>-->
          </div>
          <div class="col-md-4">
            <div class="ftco-footer-widget mb-4 ml-md-5">
              <h2 class="ftco-heading-2 text-left">Quick Links</h2>
              <ul class="list-unstyled text-left">
                
                <li class="list-item">
                  <a href="IOT-platform" class="py-2 d-block">Platform</a>
                </li>
                <li class="list-item">
                  <a href="solutions" class="py-2 d-block">Solutions</a>
                </li>
                <li class="list-item">
                  <a href="coe" class="py-2 dblock">Center Of Excellence</a>
                </li>
                <li class="list-item">
                  <a href="career" class="py-2 d-block">Career</a>
                </li>
                <li class="list-item">
                  <a href="contact" class="py-2 d-block">Contact</a>
                </li>
              </ul>
            </div>
          </div>
  
          <div class="col-md-4">
            <div class="ftco-footer-widget mb-4">
              <h2 class="ftco-heading-2 text-left">Contact Info</h2>
              <div class="block-23 mb-3">
                <ul>
                  <li>
                    <span class="icon icon-map-marker"></span><span class="text">Woodsman Smart Retail Solutions LLP<br />
                      Bangalore, India</span>
                  </li>
                  <li>
                    <a href="#"><span class="icon icon-phone"></span><span class="text">+91 99809 36856</span></a>
                  </li>
                  <li>
                    <a href="#"><span class="icon icon-envelope"></span><span
                        class="text">info@woodsmanglobal.com</span></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="row">
        <div class="col-md-12 text-center">
          <p>
            <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
            Copyright &copy;
            <script>
              document.write(new Date().getFullYear());
            </script>
            <a href="" target="_blank">Woodsman Smart Retail Solutions LLP</a>
            <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
          </p>
        </div>
      </div>
    </footer>

  <!-- loader -->
  <!-- <div id="ftco-loader" class="show fullscreen"><svg class="circular" width="48px" height="48px">
      <circle class="path-bg" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke="#eeeeee" />
      <circle class="path" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke-miterlimit="10"
        stroke="#F96D00" />
    </svg></div> -->

  <!-- Modal -->
  <div class="modal fade" id="modalRequest" tabindex="-1" role="dialog" aria-labelledby="modalRequestLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalRequestLabel">Request a Quote</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="form-group">
              <!-- <label for="appointment_name" class="text-black">Full Name</label> -->
              <input type="text" class="form-control" id="appointment_name" placeholder="Full Name" />
            </div>
            <div class="form-group">
              <!-- <label for="appointment_email" class="text-black">Email</label> -->
              <input type="text" class="form-control" id="appointment_email" placeholder="Email" />
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <!-- <label for="appointment_date" class="text-black">Date</label> -->
                  <input type="text" class="form-control" id="appointment_date" placeholder="Date" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <!-- <label for="appointment_time" class="text-black">Time</label> -->
                  <input type="text" class="form-control" id="appointment_time" placeholder="Time" />
                </div>
              </div>
            </div>

            <div class="form-group">
              <!-- <label for="appointment_message" class="text-black">Message</label> -->
              <textarea name="" id="appointment_message" class="form-control" cols="30" rows="10"
                placeholder="Message"></textarea>
            </div>
            <div class="form-group">
              <input type="submit" value="Get a Quote" class="btn btn-primary" />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <script src="resources/js/jquery.min.js"></script>
  <script src="resources/js/jquery-migrate-3.0.1.min.js"></script>
  <script src="resources/js/popper.min.js"></script>
  <script src="resources/js/bootstrap.min.js"></script>
  <script src="resources/js/jquery.easing.1.3.js"></script>
  <script src="resources/js/jquery.waypoints.min.js"></script>
  <script src="resources/js/jquery.stellar.min.js"></script>
  <script src="resources/js/owl.carousel.min.js"></script>
  <script src="resources/js/jquery.magnific-popup.min.js"></script>
  <script src="resources/js/aos.js"></script>
  <script src="resources/js/jquery.animateNumber.min.js"></script>
  <script src="resources/js/bootstrap-datepicker.js"></script>
  <script src="resources/js/jquery.timepicker.min.js"></script>
  <script src="resources/js/scrollax.min.js"></script>
  <script
    src="resources/https://maps.googleapis.com/maps/api/js?key=AIzaSyBVWaKrjvy3MaE7SQ74_uJiULgl1JY0H2s&sensor=false"></script>
  <script src="resources/js/google-map.js"></script>
  <script src="resources/js/main.js"></script>
</body>

</html>