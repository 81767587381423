import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainContentComponent } from './main-content/main-content.component';
import { IndividualComponent } from './login/individual/individual.component';
import { CorporateComponent } from './login/corporate/corporate.component';
import { SIndividualComponent } from './signup/s-individual/s-individual.component';
import { SCorporateComponent } from './signup/s-corporate/s-corporate.component';
import { ListProductComponent } from './list-product/list-product.component';
import { CartComponent } from './cart/cart.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { ProfileComponent } from './profile/profile.component';
import { PindexComponent } from './preva/pindex/pindex.component';
import { PcontactComponent } from './preva/pcontact/pcontact.component';
import { PcoeComponent } from './preva/pcoe/pcoe.component';
import { PcareerComponent } from './preva/pcareer/pcareer.component';
import { PiotplatformComponent } from './preva/piotplatform/piotplatform.component';
import { PaboutComponent } from './preva/pabout/pabout.component';
import { PsolutionComponent } from './preva/psolution/psolution.component';
import { AssetManagementComponent } from './solutions/asset-management/asset-management.component';
import { EventManagementComponent } from './solutions/event-management/event-management.component';
import { HealthCareManagementComponent } from './solutions/health-care-management/health-care-management.component';
import { LibraryManagementComponent } from './solutions/library-management/library-management.component';
import { TrackingManagementComponent } from './solutions/tracking-management/tracking-management.component';


const routes: Routes = [
  { path: 'shop', component: MainContentComponent },
  { path: 'list_product', component: ListProductComponent },
  { path: 'product_details', component: ProductDetailsComponent },
  { path: 'cart', component: CartComponent },
  { path: 'login/individual', component: IndividualComponent },
  { path: 'login/corporate', component: CorporateComponent },
  { path: 'signup/individual', component: SIndividualComponent },
  { path: 'signup/corporate', component: SCorporateComponent },
  { path: 'profile', component: ProfileComponent },


  // preva
  { path: '', component: PindexComponent },
  { path: 'coe', component: PcoeComponent },
  { path: 'career', component: PcareerComponent },
  { path: 'IOT-platform', component: PiotplatformComponent },
  { path: 'contact', component: PcontactComponent },
  { path: 'solutions', component: PsolutionComponent },
  { path: 'solutions/asset-management', component: AssetManagementComponent },
  { path: 'solutions/event-management', component: EventManagementComponent },
  { path: 'solutions/health-care-management', component: HealthCareManagementComponent },
  { path: 'solutions/library-management', component: LibraryManagementComponent },
  { path: 'solutions/tracking-management', component: TrackingManagementComponent },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
