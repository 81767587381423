import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pteam',
  templateUrl: './pteam.component.html',
  styleUrls: ['./pteam.component.scss']
})
export class PteamComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
