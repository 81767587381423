import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EcomService } from 'src/app/ecom.service';

@Component({
  selector: 'app-s-individual',
  templateUrl: './s-individual.component.html',
  styleUrls: ['./s-individual.component.scss']
})
export class SIndividualComponent implements OnInit {

  signupForm: FormGroup;
  successMessage: string;
  errorMessage: any;
  index_data: any;

  constructor(private fb: FormBuilder,private es:EcomService) {
    this.signupForm = this.fb.group({
      username: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mobileNo: ['', [Validators.required, Validators.pattern(/^[0-9]+$/)]],
      password1: ['', Validators.required],
      password2: ['', Validators.required],
    });
  }
  ngOnInit(): void {
    this.index_data = JSON.parse(localStorage.getItem("index_data"));
  }

  onSubmit() {
    if (this.signupForm.valid) {
      console.log(this.signupForm.value);
      // Perform the signup action
      this.es.login(this.signupForm.value).subscribe(
        data=>{
          console.log(
            data
          )
          this.successMessage = "done"
        },
        error=>{
          console.log(
            this.errorMessage = error.error
          )
        }
      );
    }
  }
}