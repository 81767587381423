<mat-sidenav-container>
    <mat-sidenav #sidenav mode="side" position="start">
      <!-- Side nav content here -->
       <div fxLayout="row" >
        <button mat-icon-button (click)="sidenav.toggle()" aria-label="Example icon-button with menu icon" style="color: black;">
          <mat-icon>close</mat-icon>
        </button>
        <span class="example-spacer"></span>
  
        <section>
          <!-- <div class="example-label">
            
            <button mat-button [matMenuTriggerFor]="menu">My account</button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="sidenav.close()" [routerLink]="'/login/individual'">Individual Login</button>
              <button mat-menu-item (click)="sidenav.close()" [routerLink]="'/login/corporate'">Corporate Login</button>
            </mat-menu>
          </div> -->
          <div class="example-label">
            <button mat-button [matMenuTriggerFor]="menu">My account</button>
            <mat-menu #menu="matMenu">
              <ng-container *ngIf="isLoggedIn">
                <button mat-menu-item (click)="navigateToProfile()"><mat-icon>account_circle</mat-icon> Profile</button>
                <button mat-menu-item (click)="logout()"><mat-icon>power_settings_new</mat-icon> Logout</button>
              </ng-container>
              <ng-container *ngIf="!isLoggedIn">
                <button mat-menu-item (click)="sidenav.close()" [routerLink]="'/login/individual'">Individual Login</button>
                <button mat-menu-item (click)="sidenav.close()" [routerLink]="'/login/corporate'">Corporate Login</button>
              </ng-container>
            </mat-menu>
          </div>
          <div class="example-label" [routerLink]="'/cart'">Shopping bag (0)</div>
        </section>
       </div>
       <div style="padding: 40px;">
        <div fxLayout="column" fxLayoutAlign="center center">
          <mat-button-toggle-group #group1="matButtonToggleGroup" aria-label="Item Selection" (change)="onToggleChange($event)">
            <mat-button-toggle *ngFor="let tab of tabs" [value]="tab.label">
              {{ tab.label }}
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        
        <div *ngFor="let tab of tabs" [hidden]="group1.value !== tab.label" style="padding: 10px;">
          <mat-list role="list">
            <mat-list-item *ngFor="let item of tab.items" role="listitem">
              <span (click)="onClick({ item: item, label: tab.label })">
                {{ item }}
              </span>
            </mat-list-item>
          </mat-list>
        </div>
        

       </div>
      
  
  
    </mat-sidenav>
    <mat-sidenav #sidenav2 mode="side" position="start" style="width: fit-content;margin-left: 150px;margin-top: 0px;scrollbar-width: 0px;">
      <!-- Side nav content here -->
       <div fxLayout="row" fxLayoutAlign="center center">
        <button mat-icon-button (click)="sidenav2.toggle()" aria-label="Example icon-button with menu icon" style="color: black;">
          <mat-icon>close</mat-icon>
        </button>
       </div>
       <p style="font-size: 50px;padding: 20px;color: white;" [routerLink]="''">{{this.org_name}}</p>
       <div style="padding: 40px;">
        <mat-button-toggle-group #group="matButtonToggleGroup" aria-label="Item Selection" (change)="onToggleChange($event)">
          <mat-button-toggle *ngFor="let tab of tabs" [value]="tab.label">
            {{ tab.label }}
          </mat-button-toggle>
        </mat-button-toggle-group>
        
        <div *ngFor="let tab of tabs" [hidden]="group.value !== tab.label" style="padding: 10px;">
          <mat-list role="list">
            <mat-list-item *ngFor="let item of tab.items" role="listitem">
              <span (click)="onClick({ item: item, label: tab.label })">
                {{ item }}
              </span>
            </mat-list-item>
          </mat-list>
        </div>
        

       </div>


       <!-- <mat-tab-group animationDuration="0ms">
        <mat-tab *ngFor="let tab of tabs" >
          <ng-template matTabLabel>{{ tab.label }}</ng-template>
          <div style="padding: 10px;">
            <mat-list role="list">
              <mat-list-item *ngFor="let item of tab.items" role="listitem">
                {{ item }}
              </mat-list-item>
            </mat-list>
          </div>
        </mat-tab>
      </mat-tab-group> -->
      
  
  
    </mat-sidenav>
  
    <mat-sidenav-content>
      <!-- Your main content here -->
      <mat-toolbar fxLayout="row" fxHide.xs>
        <button mat-icon-button (click)="sidenav2.toggle()" aria-label="Example icon-button with menu icon" style="color: rgb(0, 0, 0);">
          <mat-icon>menu</mat-icon>
        </button>
        <span style="margin-left: 100px;"></span>
        <span style="font-size: 50px;" [routerLink]="''">{{ this.org_name }}</span>
        <span class="example-spacer"></span>
        <section>
          <div class="example-label">
            
            <!-- <div class="example-label">
            
              <button mat-button [matMenuTriggerFor]="menu">My account</button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="sidenav.close()" [routerLink]="'/login/individual'">Individual Login</button>
                <button mat-menu-item (click)="sidenav.close()" [routerLink]="'/login/corporate'">Corporate Login</button>
              </mat-menu>
            </div> -->
            <div class="example-label">
              <button mat-button [matMenuTriggerFor]="menu">My account</button>
              <mat-menu #menu="matMenu">
                <ng-container *ngIf="isLoggedIn">
                  <button mat-menu-item (click)="navigateToProfile()">Profile</button>
                  <button mat-menu-item (click)="logout()">Logout</button>
                </ng-container>
                <ng-container *ngIf="!isLoggedIn">
                  <button mat-menu-item (click)="sidenav.close()" [routerLink]="'/login/individual'">Individual Login</button>
                  <button mat-menu-item (click)="sidenav.close()" [routerLink]="'/login/corporate'">Corporate Login</button>
                </ng-container>
              </mat-menu>
            </div>
          </div>
          <div class="example-label">HELP</div>
          <div class="example-label" [routerLink]="'/cart'">Shopping bag (0)</div>
        </section>
      </mat-toolbar>
  
      <mat-toolbar fxLayout="row" fxHide.gt-xs>
        <ng-container *ngIf="showBackButton; else defaultToolbar">
          <button mat-icon-button (click)="goBack()" aria-label="Back" style="color: black;">
            <mat-icon>arrow_back</mat-icon>
          </button>
        </ng-container>
        <ng-template #defaultToolbar>
          <button mat-icon-button (click)="sidenav.toggle()" aria-label="Example icon-button with menu icon" style="color: rgb(0, 0, 0);">
            <mat-icon>menu</mat-icon>
          </button>
          <span style="font-size: 25px;" [routerLink]="''">{{this.org_name}}</span>
        </ng-template>

        <span class="example-spacer"></span>
        <section>
          <div class="example-label" [routerLink]="'/cart'">Shopping bag (0)</div>
        </section>
      </mat-toolbar>
      <!-- <div style="height: 60px;background-color: transparent;">

      </div> -->
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
  