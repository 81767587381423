<!DOCTYPE html>
<html lang="en">

<head>
    <link rel="icon" href="/assets/resources/images/logo/favicon.ico" type="img" sizes="16x16" />
    <title>Career | Woodsman Smart Retail Solutions LLP</title>
    <meta charset="utf-8" />
    <!-- <meta  -->
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

    <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700" rel="stylesheet" />
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css"
        integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossorigin="anonymous" />

    <link rel="stylesheet" href="/assets/resources/css/open-iconic-bootstrap.min.css" />
    <link rel="stylesheet" href="/assets/resources/css/animate.css" />

    <link rel="stylesheet" href="/assets/resources/css/owl.carousel.min.css" />
    <link rel="stylesheet" href="/assets/resources/css/owl.theme.default.min.css" />
    <link rel="stylesheet" href="/assets/resources/css/magnific-popup.css" />

    <link rel="stylesheet" href="/assets/resources/css/aos.css" />

    <link rel="stylesheet" href="/assets/resources/css/ionicons.min.css" />

    <link rel="stylesheet" href="/assets/resources/css/bootstrap-datepicker.css" />
    <link rel="stylesheet" href="/assets/resources/css/jquery.timepicker.css" />

    <link rel="stylesheet" href="/assets/resources/css/flaticon.css" />
    <link rel="stylesheet" href="/assets/resources/css/icomoon.css" />
    <link rel="stylesheet" href="/assets/resources/css/style.css" />
</head>

<body>
    <nav class="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light" id="ftco-navbar">
        <div class="container">
            <a class="navbar-brand" href=""><img src="resources/images/logo/LOGO.png" /></a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav"
                aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="oi oi-menu"></span> Menu
            </button>

            <div class="collapse navbar-collapse" id="ftco-nav">
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item">
                        <a href="IOT-platform" class="nav-link">Platform</a>
                    </li>
                    <li class="nav-item dropdown" (mouseenter)="onMouseEnter($event)"
                        (mouseleave)="onMouseLeave($event)">
                        <a class="nav-link" [attr.aria-expanded]="isDropdownOpen" href="solutions">Solutions</a>
                        <ul class="dropdown-menu" [class.show]="isDropdownOpen">
                            <li>
                                <a href="solutions/asset-management">ASSET MANAGEMENT</a>
                            </li>
                            <div class="dropdown-divider"></div>
                            <li>
                                <a href="solutions/event-management">EVENT MANAGEMENT</a>
                            </li>
                            <div class="dropdown-divider"></div>
                            <li>
                                <a href="solutions/health-care-management">HEALTHCARE MANAGEMENT</a>
                            </li>
                            <div class="dropdown-divider"></div>
                            <li>
                                <a href="solutions/library-management">LIBRARY MANAGEMENT</a>
                            </li>

                            <div class="dropdown-divider"></div>
                            <li>
                                <a href="solutions/tracking-management">TRACKING MANAGEMENT</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item"><a class="nav-link" href="coe">Coe</a></li>
                    <li class="nav-item">
                        <a href="career" class="nav-link">Career</a>
                    </li>

                    <!--<li class="nav-item"><a href="blog.html" class="nav-link">Blog</a></li>-->
                    <li class="nav-item">
                        <a href="contact" class="nav-link">Contact</a>
                    </li>
                    <!-- <li class="nav-item cta"><a href="contact" class="nav-link" data-toggle="modal" data-target="#modalRequest"><span>Get a Quote</span></a></li>-->
                </ul>
            </div>
        </div>
    </nav>
    <!-- END nav -->

    <div class="hero-wrap hero-wrap-2" data-stellar-background-ratio="0.5">
        <!-- <div class="overlay"></div>-->
        <div class="container-fluid">
            <div class="row no-gutters d-flex slider-text align-items-center justify-content-center"
                data-scrollax-parent="true">
                <div class="col-md-6 ftco-animate text-center" data-scrollax=" properties: { translateY: '70%' }">
                    <p class="breadcrumbs" data-scrollax="properties: { translateY: '30%', opacity: 1.6 }">
                        <span class="mr-2"><a href="">Home</a></span><span>Careers</span>
                    </p>
                    <h1 class="mb-3 bread" data-scrollax="properties: { translateY: '30%', opacity: 1.6 }">
                        Woodsman Is Hiring
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <section class="ftco-section ftco-degree-bg">
        <div class="container">
            <div class="row">
                <!-- Job Description Column (Main Content) -->
                <div class="col-md-8 ftco-animate heading-section">
                    <!-- Business Development Executive -->
                    <h2 class="mb-3">Business Development Executive</h2>
                    <p>
                        Positions Vacant: 3<br />
                        Experience: 2+ years of relevant experience in sales and
                        marketing.
                    </p>
                    <p>
                        Professionally and effectively employ sales skills to achieve
                        targeted sales.
                    </p>
                    <p>
                        Increase the level of sales and business mix from individual
                        clients. Maintain an accurate and current database.
                    </p>
                    <p>Identify and develop sources of potential clients.</p>
                    <p>
                        Effectively communicate with clients to gain commitment to
                        purchase.
                    </p>
                    <p>
                        Effectively communicate with clients via telephone, face-to-face,
                        and written communication.
                    </p>
                    <p>Good communication skills.</p>
                    <p>
                        Lead generation, Cold Calling, Creating proposals for products,
                        giving presentations to customers.
                    </p>
                    <input type="submit" value="Apply Now" class="btn btn-primary py-3 px-5" />

                    <!-- Business Development Manager -->
                    <h2 class="mb-3 mt-5">Business Development Manager</h2>
                    <p>
                        Positions Vacant: 2<br />
                        Experience: 5+ years of relevant sales and marketing experience.
                    </p>
                    <p>Requirements:</p>
                    <p>
                        Professionally and effectively employ sales skills to achieve
                        targeted sales.
                    </p>
                    <p>
                        Identify and develop sources of potential clients. Effectively
                        communicate with clients to gain commitment to purchase.
                    </p>
                    <p>
                        Good communication skills. Lead generation, Cold Calling, Creating
                        proposals for products, giving presentations to customers.
                    </p>
                    <p>
                        Should Build various revenue generation strategies. Experience in
                        selling GPS, RFID, CCTV, biometrics, barcode, electronic security
                        systems products.
                    </p>
                    <input type="submit" value="Apply Now" class="btn btn-primary py-3 px-5" />

                    <!-- Pre Sales Executive -->
                    <h2 class="mb-3 mt-5">Pre Sales Executive</h2>
                    <p>
                        Positions Vacant: 2<br />
                        Experience: 5+ years of relevant sales and marketing experience.
                    </p>
                    <p>Requirements:</p>
                    <p>
                        Working closely with the sales team on proposals and pitches for
                        business.
                    </p>
                    <p>Attending meetings with business development managers.</p>
                    <p>
                        Putting together business cases for the sales teams to support
                        their proposals.
                    </p>
                    <p>
                        Working closely and collaboratively with customers to devise
                        effective solutions.
                    </p>
                    <p>
                        Ensuring that the company’s product can deliver on the customer’s
                        requirements.
                    </p>
                    <p>
                        Working closely with customers to understand and capture
                        requirements.
                    </p>
                    <p>
                        Replying to customers following requests for information and
                        proposal requests.
                    </p>
                    <p>
                        Presenting proposals to customers alongside members of the sales
                        team.
                    </p>
                    <p>Providing ongoing support for customers post-implementation.</p>
                    <p>
                        Producing detailed costings for customers and ensuring the
                        contract is profitable.
                    </p>
                    <input type="submit" value="Apply Now" class="btn btn-primary py-3 px-5" />

                    <div class="pt-5 mt-5">
                        <!-- Comments Section (Optional) -->
                        <!-- Example comments section can be added here -->
                    </div>
                </div>
                <!-- End .col-md-8 -->

                <!-- Sidebar Column (Optional) -->
                <div class="col-md-4 sidebar ftco-animate">
                    <!-- Optional Sidebar Content (Search Form, Latest Jobs, etc.) -->
                    <!-- You can add widgets or other content in this section as required. -->
                </div>
                <!-- End .col-md-4 -->
            </div>
            <!-- End .row -->
        </div>
        <!-- End .container -->
    </section>
    <!-- End .ftco-section -->

    <footer class="ftco-footer ftco-bg-dark ftco-section">
        <div class="container">
          <div class="row mb-5">
            <div class="col-md-4">
              <div class="ftco-footer-widget mb-4">
                <h2 class="ftco-heading-2 text-left">Woodsman</h2>
                <p>
                  Woodsman has been established in Dec 2021 with a vision to be
                  one of the top 10 IOT Solution Providers in Asia by 2026.
                </p>
              </div>
              <!--  <ul class="ftco-footer-social list-unstyled float-md-left float-lft ">
                  <li class="ftco-animate"><a href="#"><span class="icon-twitter"></span></a></li>
                  <li class="ftco-animate"><a href="#"><span class="icon-facebook"></span></a></li>
                  <li class="ftco-animate"><a href="#"><span class="icon-instagram"></span></a></li>
                </ul>-->
            </div>
            <div class="col-md-4">
              <div class="ftco-footer-widget mb-4 ml-md-5">
                <h2 class="ftco-heading-2 text-left">Quick Links</h2>
                <ul class="list-unstyled text-left">
                  
                  <li class="list-item">
                    <a href="IOT-platform" class="py-2 d-block">Platform</a>
                  </li>
                  <li class="list-item">
                    <a href="solutions" class="py-2 d-block">Solutions</a>
                  </li>
                  <li class="list-item">
                    <a href="coe" class="py-2 dblock">Center Of Excellence</a>
                  </li>
                  <li class="list-item">
                    <a href="career" class="py-2 d-block">Career</a>
                  </li>
                  <li class="list-item">
                    <a href="contact" class="py-2 d-block">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
    
            <div class="col-md-4">
              <div class="ftco-footer-widget mb-4">
                <h2 class="ftco-heading-2 text-left">Contact Info</h2>
                <div class="block-23 mb-3">
                  <ul>
                    <li>
                      <span class="icon icon-map-marker"></span><span class="text">Woodsman Smart Retail Solutions LLP<br />
                        Bangalore, India</span>
                    </li>
                    <li>
                      <a href="#"><span class="icon icon-phone"></span><span class="text">+91 99809 36856</span></a>
                    </li>
                    <li>
                      <a href="#"><span class="icon icon-envelope"></span><span
                          class="text">info@woodsmanglobal.com</span></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
    
        <div class="row">
          <div class="col-md-12 text-center">
            <p>
              <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
              Copyright &copy;
              <script>
                document.write(new Date().getFullYear());
              </script>
              <a href="" target="_blank">Woodsman Smart Retail Solutions LLP</a>
              <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
            </p>
          </div>
        </div>
      </footer>

    <!-- loader -->
    <!-- <div id="ftco-loader" class="show fullscreen"><svg class="circular" width="48px" height="48px">
            <circle class="path-bg" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke="#eeeeee" />
            <circle class="path" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke-miterlimit="10"
                stroke="#F96D00" />
        </svg></div> -->

    <!-- Modal -->
    <div class="modal fade" id="modalRequest" tabindex="-1" role="dialog" aria-labelledby="modalRequestLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalRequestLabel">Request a Quote</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form action="#">
                        <div class="form-group">
                            <!-- <label for="appointment_name" class="text-black">Full Name</label> -->
                            <input type="text" class="form-control" id="appointment_name" placeholder="Full Name" />
                        </div>
                        <div class="form-group">
                            <!-- <label for="appointment_email" class="text-black">Email</label> -->
                            <input type="text" class="form-control" id="appointment_email" placeholder="Email" />
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <!-- <label for="appointment_date" class="text-black">Date</label> -->
                                    <input type="text" class="form-control" id="appointment_date" placeholder="Date" />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <!-- <label for="appointment_time" class="text-black">Time</label> -->
                                    <input type="text" class="form-control" id="appointment_time" placeholder="Time" />
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <!-- <label for="appointment_message" class="text-black">Message</label> -->
                            <textarea name="" id="appointment_message" class="form-control" cols="30" rows="10"
                                placeholder="Message"></textarea>
                        </div>
                        <div class="form-group">
                            <input type="submit" value="Get a Quote" class="btn btn-primary" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <script src="resources/js/jquery.min.js"></script>
    <script src="resources/js/jquery-migrate-3.0.1.min.js"></script>
    <script src="resources/js/popper.min.js"></script>
    <script src="resources/js/bootstrap.min.js"></script>
    <script src="resources/js/jquery.easing.1.3.js"></script>
    <script src="resources/js/jquery.waypoints.min.js"></script>
    <script src="resources/js/jquery.stellar.min.js"></script>
    <script src="resources/js/owl.carousel.min.js"></script>
    <script src="resources/js/jquery.magnific-popup.min.js"></script>
    <script src="resources/js/aos.js"></script>
    <script src="resources/js/jquery.animateNumber.min.js"></script>
    <script src="resources/js/bootstrap-datepicker.js"></script>
    <script src="resources/js/jquery.timepicker.min.js"></script>
    <script src="resources/js/scrollax.min.js"></script>
    <script
        src="resources/https://maps.googleapis.com/maps/api/js?key=AIzaSyBVWaKrjvy3MaE7SQ74_uJiULgl1JY0H2s&sensor=false"></script>
    <script src="resources/js/google-map.js"></script>
    <script src="resources/js/main.js"></script>
</body>

</html>