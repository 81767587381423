<!DOCTYPE html>
<html lang="en">

<head>
    <link rel="icon" href="/assets/resources/images/logo/favicon.ico" type="img" sizes="16x16" />
    <title>Platform | Woodsman Smart Retail Solutions LLP</title>
    <meta charset="utf-8" />
    <!-- <meta  -->
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <meta name="keywords"
        content="platform,internet of things,iot,iot-platform,preva,preva-systems,ios-solutions,industry-solutions,iotapplications,components,capability,database,security,API,
    business-logic,device-manager,rule-engine,device-communication,reliable,scaleable,modular,layered,diagonstic,speed-accelaration" />

    <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700" rel="stylesheet" />
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css"
        integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossorigin="anonymous" />

    <link rel="stylesheet" href="/assets/resources/css/open-iconic-bootstrap.min.css" />
    <link rel="stylesheet" href="/assets/resources/css/animate.css" />

    <link rel="stylesheet" href="/assets/resources/css/owl.carousel.min.css" />
    <link rel="stylesheet" href="/assets/resources/css/owl.theme.default.min.css" />
    <link rel="stylesheet" href="/assets/resources/css/magnific-popup.css" />

    <link rel="stylesheet" href="/assets/resources/css/aos.css" />

    <link rel="stylesheet" href="/assets/resources/css/ionicons.min.css" />

    <link rel="stylesheet" href="/assets/resources/css/bootstrap-datepicker.css" />
    <link rel="stylesheet" href="/assets/resources/css/jquery.timepicker.css" />

    <link rel="stylesheet" href="/assets/resources/css/flaticon.css" />
    <link rel="stylesheet" href="/assets/resources/css/icomoon.css" />
    <link rel="stylesheet" href="/assets/resources/css/style.css" />
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" rel="stylesheet" />
    <!-- jQuery (Required for Bootstrap) -->
    <script src="https://code.jquery.com/jquery-3.6.0.min.js"></script>

    <!-- Bootstrap Bundle (Includes Popper.js) -->
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/js/bootstrap.bundle.min.js"></script>
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" rel="stylesheet" />
</head>

<body>
    <nav class="navbar navbar-expand-lg navbar-dark ftco_navbar bg-ligh ftco-navbar-light" id="ftco-navbar">
        <div class="container">
            <a class="navbar-brand" href=""><img src="resources/images/logo/LOGO.png" /></a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav"
                aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="oi oi-menu"></span> Menu
            </button>

            <div class="collapse navbar-collapse" id="ftco-nav">
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item">
                        <a href="IOT-platform" class="nav-link">Platform</a>
                    </li>
                    <li class="nav-item dropdown" (mouseenter)="onMouseEnter($event)"
                        (mouseleave)="onMouseLeave($event)">
                        <a class="nav-link" [attr.aria-expanded]="isDropdownOpen" href="solutions">Solutions</a>
                        <ul class="dropdown-menu" [class.show]="isDropdownOpen">
                            <li>
                                <a href="solutions/asset-management">ASSET MANAGEMENT</a>
                            </li>
                            <div class="dropdown-divider"></div>
                            <li>
                                <a href="solutions/event-management">EVENT MANAGEMENT</a>
                            </li>
                            <div class="dropdown-divider"></div>
                            <li>
                                <a href="solutions/health-care-management">HEALTHCARE MANAGEMENT</a>
                            </li>
                            <div class="dropdown-divider"></div>
                            <li>
                                <a href="solutions/library-management">LIBRARY MANAGEMENT</a>
                            </li>

                            <div class="dropdown-divider"></div>
                            <li>
                                <a href="solutions/tracking-management">TRACKING MANAGEMENT</a>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item"><a class="nav-link" href="coe">Coe</a></li>
                    <li class="nav-item">
                        <a href="career" class="nav-link">Career</a>
                    </li>
                    <li class="nav-item">
                        <a href="contact" class="nav-link">Contact</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <!-- END nav -->

    <div class="hero-wrap hero-wrap-2" data-stellar-background-ratio="0.5">
        <!-- <img src="resources/images/bg/iot5.jpeg" alt="" class="hero-wrap">  -->
        <!-- <div class="overlay"></div> -->
        <div class="container-fluid">
            <div class="row no-gutters d-flex slider-text align-items-center justify-content-center"
                data-scrollax-parent="true">
                <div class="col-md-6 ftco-animate text-center" data-scrollax=" properties: { translateY: '70%' }">
                    <p class="breadcrumbs" data-scrollax="properties: { translateY: '30%', opacity: 1.6 }">
                        <span class="mr-2"><a href="">Home</a></span> <span></span>
                    </p>
                    <h1 class="mb-3 bread" data-scrollax="properties: { translateY: '30%', opacity: 1.6 }">
                        Platform
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <section class="ftco-section ftco-services">
        <div class="container">
            <!-- Platform Overview Section -->
            <div class="row justify-content-center mb-5 pb-5">
                <div class="col-md-7 text-center ftco-animate">
                    <h2 class="mb-2">PLATFORM OVERVIEW</h2>
                </div>
            </div>

            <!-- Platform Image -->
            <div class="img">
                <div class="img-justify-content-center">
                    <img src="resources/images/bg/platform_300dpi.png" class="comp" />
                </div>
            </div>

            <p>
                <strong>Woodsman's IoT Platform</strong> is an award-winning
                cloud-based application accelerator platform, enabling enterprises to
                quickly connect any device to the internet in a flexible manner.
                <strong>Woodsman's IoT platform</strong> then derives meaningful
                information from connected devices, providing them to web and mobile
                applications for impactful decision-making.
            </p>

            <!-- Benefits Section -->
            <div class="container">
                <div class="row justify-content-left mb-5 pb-5">
                    <div class="col-md-12 text-left ftco-animate">
                        <h2 class="mb-2 text-center">
                            BENEFITS OF Woodsman's IoT PLATFORM
                        </h2>
                        <ul>
                            <li>
                                <i class="fas fa-chevron-circle-right" style="color: #2f89fc"></i>
                                <strong>Simplify</strong> development and deployment of your
                                automation.
                            </li>
                            <li>
                                <i class="fas fa-chevron-circle-right" style="color: #2f89fc"></i>
                                <strong>Accelerate</strong> Time-To-Market.
                            </li>
                            <li>
                                <i class="fas fa-chevron-circle-right" style="color: #2f89fc"></i>
                                <strong>Reduce cost</strong> of automation through superior
                                ROI.
                            </li>
                            <li>
                                <i class="fas fa-chevron-circle-right" style="color: #2f89fc"></i>
                                <strong>Scale</strong> your solution to enterprise grade with
                                automated operational support and real-time analytics.
                            </li>
                            <li>
                                <i class="fas fa-chevron-circle-right" style="color: #2f89fc"></i>
                                Uncover unknown <strong>risks</strong> through sensor
                                insights.
                            </li>
                            <li>
                                <i class="fas fa-chevron-circle-right" style="color: #2f89fc"></i>
                                <strong>Deliver</strong> more than what your customer
                                needs—faster, better, and cheaper.
                            </li>
                            <li>
                                <i class="fas fa-chevron-circle-right" style="color: #2f89fc"></i>
                                <strong>Protect</strong> your existing
                                <strong>investment</strong> by connecting your devices to our
                                cloud.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- Components Section -->
            <div class="container">
                <div class="row justify-content-center mb-5 pb-5">
                    <div class="col-md-7 text-center ftco-animate">
                        <h2 class="mb-2">COMPONENTS</h2>
                    </div>
                </div>
                <div class="img">
                    <div class="img-justify-content-center">
                        <img src="resources/images/dash/components.png" class="comp" /><br /><br />
                    </div>
                </div>
            </div>

            <!-- Platform Capabilities Section -->
            <div class="ftco-animate">
                <h2 class="mb-2 text-center">PLATFORM CAPABILITIES</h2>
                <br /><br />
            </div>

            <div class="row">
                <!-- Reliable -->
                <div class="col-md-4 d-flex align-self-stretch ftco-animate">
                    <div class="media block-6 services d-block text-center">
                        <div class="d-flex justify-content-center">
                            <div class="icon">
                                <img src="resources/images/icons/reliable.png" />
                            </div>
                        </div>
                        <div class="media-body p-2 mt-3">
                            <h3 class="heading">Reliable</h3>
                            <p>
                                All platform components are monitored, and automatic failure
                                recovery is possible where applicable.
                            </p>
                        </div>
                    </div>
                </div>

                <!-- Scalable -->
                <div class="col-md-4 d-flex align-self-stretch ftco-animate">
                    <div class="media block-6 services d-block text-center">
                        <div class="d-flex justify-content-center">
                            <div class="icon">
                                <img src="resources/images/icons/scalable.png" />
                            </div>
                        </div>
                        <div class="media-body p-2 mt-3">
                            <h3 class="heading">Scalable</h3>
                            <p>
                                Flexibility to run components on single or multiple hardware
                                based on deployment needs, with increased transaction handling
                                capacity.
                            </p>
                        </div>
                    </div>
                </div>

                <!-- Modular -->
                <div class="col-md-4 d-flex align-self-stretch ftco-animate">
                    <div class="media block-6 services d-block text-center">
                        <div class="d-flex justify-content-center">
                            <div class="icon">
                                <img src="resources/images/icons/modular.png" />
                            </div>
                        </div>
                        <div class="media-body p-2 mt-3">
                            <h3 class="heading">Modular</h3>
                            <p>
                                Well-defined components can be added, updated, or removed
                                without rebooting the system. New components can be developed
                                and added during runtime.
                            </p>
                        </div>
                    </div>
                </div>

                <!-- Layered -->
                <div class="col-md-4 d-flex align-self-stretch ftco-animate">
                    <div class="media block-6 services d-block text-center">
                        <div class="d-flex justify-content-center">
                            <div class="icon">
                                <img src="resources/images/icons/layered.png" />
                            </div>
                        </div>
                        <div class="media-body p-2 mt-3">
                            <h3 class="heading">Layered</h3>
                            <p>
                                Four layers with well-defined functions: Device Communication,
                                Data Intelligence, Business Logic, and API layers for
                                application development.
                            </p>
                        </div>
                    </div>
                </div>

                <!-- Device Agnostic -->
                <div class="col-md-4 d-flex align-self-stretch ftco-animate">
                    <div class="media block-6 services d-block text-center">
                        <div class="d-flex justify-content-center">
                            <div class="icon">
                                <img src="resources/images/icons/agnostic.png" />
                            </div>
                        </div>
                        <div class="media-body p-2 mt-3">
                            <h3 class="heading">Device Agnostic</h3>
                            <p>
                                Vendor and protocol-agnostic device connectivity, allowing
                                flexibility in device selection and protecting existing
                                investments.
                            </p>
                        </div>
                    </div>
                </div>

                <!-- Application Acceleration -->
                <div class="col-md-4 d-flex align-self-stretch ftco-animate">
                    <div class="media block-6 services d-block text-center">
                        <div class="d-flex justify-content-center">
                            <div class="icon">
                                <img src="resources/images/icons/accelerator.png" alt="accelerator" />
                            </div>
                        </div>
                        <div class="media-body p-2 mt-3">
                            <h3 class="heading">Application Acceleration</h3>
                            <p>
                                Fast and easy application development with flexible hosting
                                options and development technology.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!--<section class="ftco-section-parallax">
      <div class="parallax-img d-flex align-items-center">
        <div class="container">
          <div class="row d-flex justify-content-center">
            <div class="col-md-7 text-center heading-section heading-section-white ftco-animate">
              <h2>Subcribe to our Newsletter</h2>
              <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in</p>
              <div class="row d-flex justify-content-center mt-5">
                <div class="col-md-8">
                  <form action="#" class="subscribe-form">
                    <div class="form-group d-flex">
                      <input type="text" class="form-control" placeholder="Enter email address">
                      <input type="submit" value="Subscribe" class="submit px-3">
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>-->

    <footer class="ftco-footer ftco-bg-dark ftco-section">
        <div class="container">
          <div class="row mb-5">
            <div class="col-md-4">
              <div class="ftco-footer-widget mb-4">
                <h2 class="ftco-heading-2 text-left">Woodsman</h2>
                <p>
                  Woodsman has been established in Dec 2021 with a vision to be
                  one of the top 10 IOT Solution Providers in Asia by 2026.
                </p>
              </div>
              <!--  <ul class="ftco-footer-social list-unstyled float-md-left float-lft ">
                  <li class="ftco-animate"><a href="#"><span class="icon-twitter"></span></a></li>
                  <li class="ftco-animate"><a href="#"><span class="icon-facebook"></span></a></li>
                  <li class="ftco-animate"><a href="#"><span class="icon-instagram"></span></a></li>
                </ul>-->
            </div>
            <div class="col-md-4">
              <div class="ftco-footer-widget mb-4 ml-md-5">
                <h2 class="ftco-heading-2 text-left">Quick Links</h2>
                <ul class="list-unstyled text-left">
                  
                  <li class="list-item">
                    <a href="IOT-platform" class="py-2 d-block">Platform</a>
                  </li>
                  <li class="list-item">
                    <a href="solutions" class="py-2 d-block">Solutions</a>
                  </li>
                  <li class="list-item">
                    <a href="coe" class="py-2 dblock">Center Of Excellence</a>
                  </li>
                  <li class="list-item">
                    <a href="career" class="py-2 d-block">Career</a>
                  </li>
                  <li class="list-item">
                    <a href="contact" class="py-2 d-block">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
    
            <div class="col-md-4">
              <div class="ftco-footer-widget mb-4">
                <h2 class="ftco-heading-2 text-left">Contact Info</h2>
                <div class="block-23 mb-3">
                  <ul>
                    <li>
                      <span class="icon icon-map-marker"></span><span class="text">Woodsman Smart Retail Solutions LLP<br />
                        Bangalore, India</span>
                    </li>
                    <li>
                      <a href="#"><span class="icon icon-phone"></span><span class="text">+91 99809 36856</span></a>
                    </li>
                    <li>
                      <a href="#"><span class="icon icon-envelope"></span><span
                          class="text">info@woodsmanglobal.com</span></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
    
        <div class="row">
          <div class="col-md-12 text-center">
            <p>
              <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
              Copyright &copy;
              <script>
                document.write(new Date().getFullYear());
              </script>
              <a href="" target="_blank">Woodsman Smart Retail Solutions LLP</a>
              <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
            </p>
          </div>
        </div>
      </footer>
    <!-- loader -->
    <!-- <div id="ftco-loader" class="show fullscreen"><svg class="circular" width="48px" height="48px">
            <circle class="path-bg" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke="#eeeeee" />
            <circle class="path" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke-miterlimit="10"
                stroke="#F96D00" />
        </svg></div> -->

    <!-- Modal -->
    <div class="modal fade" id="modalRequest" tabindex="-1" role="dialog" aria-labelledby="modalRequestLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalRequestLabel">Request a Quote</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form action="#">
                        <div class="form-group">
                            <!-- <label for="appointment_name" class="text-black">Full Name</label> -->
                            <input type="text" class="form-control" id="appointment_name" placeholder="Full Name" />
                        </div>
                        <div class="form-group">
                            <!-- <label for="appointment_email" class="text-black">Email</label> -->
                            <input type="text" class="form-control" id="appointment_email" placeholder="Email" />
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <!-- <label for="appointment_date" class="text-black">Date</label> -->
                                    <input type="text" class="form-control" id="appointment_date" placeholder="Date" />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <!-- <label for="appointment_time" class="text-black">Time</label> -->
                                    <input type="text" class="form-control" id="appointment_time" placeholder="Time" />
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <!-- <label for="appointment_message" class="text-black">Message</label> -->
                            <textarea name="" id="appointment_message" class="form-control" cols="30" rows="10"
                                placeholder="Message"></textarea>
                        </div>
                        <div class="form-group">
                            <input type="submit" value="Get a Quote" class="btn btn-primary" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <script src="resources/js/jquery.min.js"></script>
    <script src="resources/js/jquery-migrate-3.0.1.min.js"></script>
    <script src="resources/js/popper.min.js"></script>
    <script src="resources/js/bootstrap.min.js"></script>
    <script src="resources/js/jquery.easing.1.3.js"></script>
    <script src="resources/js/jquery.waypoints.min.js"></script>
    <script src="resources/js/jquery.stellar.min.js"></script>
    <script src="resources/js/owl.carousel.min.js"></script>
    <script src="resources/js/jquery.magnific-popup.min.js"></script>
    <script src="resources/js/aos.js"></script>
    <script src="resources/js/jquery.animateNumber.min.js"></script>
    <script src="resources/js/bootstrap-datepicker.js"></script>
    <script src="resources/js/jquery.timepicker.min.js"></script>
    <script src="resources/js/scrollax.min.js"></script>
    <script
        src="resources/https://maps.googleapis.com/maps/api/js?key=AIzaSyBVWaKrjvy3MaE7SQ74_uJiULgl1JY0H2s&sensor=false"></script>
    <script src="resources/js/google-map.js"></script>
    <script src="resources/js/main.js"></script>
</body>

</html>