<div style="height: 60px;">

</div>
<div fxLayout="column" fxLayoutAlign="center center" class="container">
    <mat-card class="login-container">
      <div fxLayout="column" fxLayoutAlign="center center" class="text-center py-1">
        <p>Welcome to</p>
        <!-- <div style="height: 130px;">
          <img alt="Center Logo" style="width: 100%; height: 100%; object-fit: contain; object-position: center center;" src="https://saasapps.in:2082/media/logo1_Ln95AOw.jpg">
        </div> -->
        <h1>
          {{this.index_data.org_name.toUpperCase()}}
  
        </h1>
        
      </div>
      <div id="otpDialog" *ngIf="false">
        OTP has been sent to your email address.
      </div>
      
      <form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Username</mat-label>
          <input matInput formControlName="username" placeholder="Enter username">
        </mat-form-field>
  
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>First Name</mat-label>
          <input matInput formControlName="firstName" placeholder="Enter first name">
        </mat-form-field>
  
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Last Name</mat-label>
          <input matInput formControlName="lastName" placeholder="Enter last name">
        </mat-form-field>
  
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Email address</mat-label>
          <input matInput formControlName="email" placeholder="Enter email">
        </mat-form-field>
  
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Mobile Number</mat-label>
          <input matInput formControlName="mobileNo" placeholder="Enter mobile number">
        </mat-form-field>
  
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Password</mat-label>
          <input matInput [type]="'password'" formControlName="password1" placeholder="Enter password">
        </mat-form-field>
  
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Confirm Password</mat-label>
          <input matInput [type]="'password'" formControlName="password2" placeholder="Confirm password">
        </mat-form-field>
  
        <div *ngIf="errorMessage" class="alert alert-danger">
          {{ errorMessage }}
        </div>
  
        <div *ngIf="successMessage" class="alert alert-success">
          {{ successMessage }}
        </div>
        
        <div fxLayout="column" fxLayoutAlign="center stretch" class="full-width" fxLayoutGap="8px">
          <button mat-raised-button color="white" type="submit">CREATE ACCOUNT</button>
          <!-- <a mat-button routerLink="/login/individual" color="accent">Login</a> -->
        </div>
      </form>
      <div fxLayout="column" fxLayoutAlign="center stretch" class="full-width" fxLayoutGap="8px">
        <button mat-raised-button color="white" >Loign</button>
        <!-- <a mat-button routerLink="/login/individual" color="accent">Login</a> -->
      </div>
    </mat-card>
  </div>
  