import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EcomService } from 'src/app/ecom.service';
import { HeaderComponent } from 'src/app/header/header.component';
@Component({
  selector: 'app-individual',
  templateUrl: './individual.component.html',
  styleUrls: ['./individual.component.scss']
})
export class IndividualComponent implements OnInit {

  loginForm: FormGroup;
  successMessage: any;
  errorMessage: any;
  index_data: any;

  constructor(private route: ActivatedRoute, private router: Router,private fb: FormBuilder,private es:EcomService,public hc:HeaderComponent) {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  ngOnInit(): void {

    this.index_data = JSON.parse(localStorage.getItem("index_data"));
  }

  onSubmit() {
    if (this.loginForm.valid) {
      console.log(this.loginForm.value);
      this.es.login(this.loginForm.value).subscribe(
        data=>{
          console.log(
            data
          )
          localStorage.setItem("currentUser",JSON.stringify(data));
          this.successMessage = "done"
          this.router.navigate([''],);
          this.hc.ngOnInit();
          
          
        },
        error=>{
          console.log(
            this.errorMessage = error.error.__all__
          )
        }
      );
      // Perform the login action
    }
  }
}