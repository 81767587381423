// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-profile',
//   templateUrl: './profile.component.html',
//   styleUrls: ['./profile.component.scss']
// })
// export class ProfileComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }




import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  profileForm: FormGroup;
  isLoggedIn: any;

  constructor(private route: ActivatedRoute, private router: Router,private fb: FormBuilder) {}

  ngOnInit(): void {

    this.profileForm = this.fb.group({
      username: ['', Validators.required],
      con_type: [''],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      mobile_no: ['', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]],
      email: ['', [Validators.required, Validators.email]],
      adress: [''],
      city: [''],
      country: [''],
      about: ['']
    });
    this.isLoggedIn = JSON.parse(localStorage.getItem('currentUser'))
    if (!this.isLoggedIn){
      this.router.navigate([''],);
    }
    console.log(
      this.isLoggedIn
    )
    this.profileForm.patchValue({
      username: this.isLoggedIn['username'],
      con_type: this.isLoggedIn['con_type'],
      first_name: this.isLoggedIn['first_name'],
      last_name: this.isLoggedIn['last_name'],
      mobile_no: this.isLoggedIn['mobile_no'],
      email: this.isLoggedIn['email'],
      adress: this.isLoggedIn['adress'],
      city: this.isLoggedIn['city'],
      country: this.isLoggedIn['country'],
      about: this.isLoggedIn['about']
    })
  }

  onSubmit() {
    if (this.profileForm.valid) {
      console.log('Form Data:', this.profileForm.value);
      // Perform save operation
    } else {
      // Handle form errors
    }
  }

  onCancel() {
    window.history.back();
  }
}

