<!DOCTYPE html>
<html lang="en">

<head>
  <link rel="icon" href="/assets/resources/images/logo/favicon.ico" type="img" sizes="16x16" />
  <title>About | Woodsman Smart Retail Solutions LLP</title>
  <meta charset="utf-8" />
  <!-- <meta  -->
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
  <meta name="keywords" content="platform,internet of things,iot,iot-platform,preva,preva-systems,ios-solutions,industry-solutions,iotapplications,mission,goals,contact,address,
    message,about-us,people,partners" />

  <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700" rel="stylesheet" />

  <link rel="stylesheet" href="/assets/resources/css/open-iconic-bootstrap.min.css" />
  <link rel="stylesheet" href="/assets/resources/css/animate.css" />

  <link rel="stylesheet" href="/assets/resources/css/owl.carousel.min.css" />
  <link rel="stylesheet" href="/assets/resources/css/owl.theme.default.min.css" />
  <link rel="stylesheet" href="/assets/resources/css/magnific-popup.css" />

  <link rel="stylesheet" href="/assets/resources/css/aos.css" />

  <link rel="stylesheet" href="/assets/resources/css/ionicons.min.css" />

  <link rel="stylesheet" href="/assets/resources/css/bootstrap-datepicker.css" />
  <link rel="stylesheet" href="/assets/resources/css/jquery.timepicker.css" />

  <link rel="stylesheet" href="/assets/resources/css/flaticon.css" />
  <link rel="stylesheet" href="/assets/resources/css/icomoon.css" />
  <link rel="stylesheet" href="/assets/resources/css/style.css" />
</head>

<body>
  <nav class="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light" id="ftco-navbar">
    <div class="container">
      <a class="navbar-brand" href=""><img src="../resources/images/logo/LOGO.png" /></a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav"
        aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="oi oi-menu"></span> Menu
      </button>

      <div class="collapse navbar-collapse" id="ftco-nav">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a href="IOT-platform" class="nav-link">Platform</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link" href="solutions">Solutions</a>
            <ul class="dropdown-menu">
              <li>
                <a href="../solutions/asset-management.html">ASSET MANAGEMENT</a>
              </li>
              <div class="dropdown-divider"></div>
              <li>
                <a href="../solutions/event-management.html">EVENT MANAGEMENT</a>
              </li>
              <div class="dropdown-divider"></div>
              <li>
                <a href="../solutions/health-care-management.html">HEALTHCARE MANAGEMENT</a>
              </li>
              <div class="dropdown-divider"></div>
              <li>
                <a href="../solutions/library-management.html">LIBRARY MANAGEMENT</a>
              </li>
              <div class="dropdown-divider"></div>
              <li>
                <a href="../solutions/tracking-management.html">TRACKING MANAGEMENT</a>
              </li>
            </ul>
          </li>

          <li class="nav-item"><a class="nav-link" href="coe">coe</a></li>
          <li class="nav-item">
            <a href="career" class="nav-link">Career</a>
          </li>

          <!--<li class="nav-item"><a href="blog.html" class="nav-link">Blog</a></li>-->
          <li class="nav-item">
            <a href="contact" class="nav-link">Contact</a>
          </li>
          <!-- <li class="nav-item cta"><a href="contact" class="nav-link" data-toggle="modal" data-target="#modalRequest"><span>Get a Quote</span></a></li>-->
        </ul>
      </div>
    </div>
  </nav>
  <!-- END nav -->

  <div class="hero-wrap hero-wrap-2" data-stellar-background-ratio="0.5">
    <!-- <img src="resources/images/bg/iot5.jpeg" alt="" class="hero-wrap">  -->
    <!-- <div class="overlay"></div> -->
    <div class="row no-gutters d-flex slider-text align-items-center justify-content-center"
      data-scrollax-parent="true">
      <div class="col-md-6 ftco-animate text-center" data-scrollax=" properties: { translateY: '70%' }">
        <p class="breadcrumbs" data-scrollax="properties: { translateY: '30%', opacity: 1.6 }">
          <span class="mr-2"><a href="">Home</a></span> <span></span>
        </p>
        <h1 class="mb-3 bread" data-scrollax="properties: { translateY: '30%', opacity: 1.6 }">
          About-us
        </h1>
      </div>
    </div>
  </div>

  <section class="ftco-section">
    <div class="container">
      <div class="row d-md-flex">
        <!-- Image Section -->
        <div class="col-md-6 ftco-animate img about-image">
          <img src="../resources/images/dash/53.jpg" alt="About Woodsman" class="img-fluid" />
        </div>

        <!-- Content Section -->
        <div class="col-md-6 ftco-animate p-md-5">
          <div class="row">
            <div class="col-md-12 nav-link-wrap mb-5">
              <!-- Tab Navigation -->
              <div class="nav ftco-animate nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                <a class="nav-link active" id="v-pills-whatwedo-tab" data-toggle="pill" href="#v-pills-whatwedo"
                  role="tab" aria-controls="v-pills-whatwedo" aria-selected="true">What we do</a>
                <a class="nav-link" id="v-pills-mission-tab" data-toggle="pill" href="#v-pills-mission" role="tab"
                  aria-controls="v-pills-mission" aria-selected="false">Our mission</a>
                <a class="nav-link" id="v-pills-goal-tab" data-toggle="pill" href="#v-pills-goal" role="tab"
                  aria-controls="v-pills-goal" aria-selected="false">Our goal</a>
              </div>
            </div>

            <div class="col-md-12 d-flex align-items-center">
              <!-- Tab Content -->
              <div class="tab-content ftco-animate" id="v-pills-tabContent">
                <!-- What We Do Tab -->
                <div class="tab-pane fade show active" id="v-pills-whatwedo" role="tabpanel"
                  aria-labelledby="v-pills-whatwedo-tab">
                  <div>
                    <h2 class="mb-4">We Offer Good Quality Services</h2>
                    <p>
                      Woodsman was established in October 2012 with the vision
                      to become one of the top 3 IoT Solution Providers in
                      Asia by 2026. We develop Real-Time Material Tracking &
                      Management Solutions for industries and enterprises,
                      utilizing our flagship IoT Cloud platform.
                    </p>
                  </div>
                </div>

                <!-- Our Mission Tab -->
                <div class="tab-pane fade" id="v-pills-mission" role="tabpanel" aria-labelledby="v-pills-mission-tab">
                  <div>
                    <h2 class="mb-4">Exceptional IoT Solutions</h2>
                    <p>
                      Woodsman IoT platform stands apart with simplified
                      device connectivity and management, providing customers
                      with a choice of vendors and devices. It enables
                      automation, location intelligence, remote device
                      management, and mobility for Tag, Track & Trace
                      solutions, enriched with analytics for industries and
                      enterprises.
                    </p>
                  </div>
                </div>

                <!-- Our Goal Tab -->
                <div class="tab-pane fade" id="v-pills-goal" role="tabpanel" aria-labelledby="v-pills-goal-tab">
                  <div>
                    <h2 class="mb-4">To Develop High Quality IoT Services</h2>
                    <p>
                      Woodsman continues to improve its solutions in selected
                      verticals with enriched workflows and a hyper-scalable
                      IoT platform that includes analytics. We have set up
                      Centers of Excellence in large universities to build IoT
                      skills among fresh talent and to make IoT ubiquitous.
                      Preva Systems has developed and delivered an IoT
                      Certification Course to these universities. Our platform
                      has been chosen by IESA/NETRA and NASSCOM for nationwide
                      skill development initiatives.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--<section class="ftco-section testimony-section bg-light">
      <div class="container">
        <div class="row justify-content-center mb-5 pb-3">
          <div class="col-md-7 text-center heading-section ftco-animate">
            <h2 class="mb-2">Testimony</h2>
            <span class="subheading">Our Happy Customer Says</span>
          </div>
        </div>
        <div class="row justify-content-center ftco-animate">
          <div class="col-md-8">
            <div class="carousel-testimony owl-carousel ftco-owl">
              <div class="item">
                <div class="testimony-wrap p-4 pb-5">
                  <div class="user-img mb-5" style="background-image: url(images/person_1.jpg)">
                    <span class="quote d-flex align-items-center justify-content-center">
                      <i class="icon-quote-left"></i>
                    </span>
                  </div>
                  <div class="text text-center">
                    <p class="mb-5">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar.</p>
                    <p class="name">Dennis Green</p>
                    <span class="position">Marketing Manager</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="testimony-wrap p-4 pb-5">
                  <div class="user-img mb-5" style="background-image: url(images/person_2.jpg)">
                    <span class="quote d-flex align-items-center justify-content-center">
                      <i class="icon-quote-left"></i>
                    </span>
                  </div>
                  <div class="text text-center">
                    <p class="mb-5">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                    <p class="name">Dennis Green</p>
                    <span class="position">Interface Designer</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="testimony-wrap p-4 pb-5">
                  <div class="user-img mb-5" style="background-image: url(images/person_3.jpg)">
                    <span class="quote d-flex align-items-center justify-content-center">
                      <i class="icon-quote-left"></i>
                    </span>
                  </div>
                  <div class="text text-center">
                    <p class="mb-5">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                    <p class="name">Dennis Green</p>
                    <span class="position">UI Designer</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="testimony-wrap p-4 pb-5">
                  <div class="user-img mb-5" style="background-image: url(images/person_1.jpg)">
                    <span class="quote d-flex align-items-center justify-content-center">
                      <i class="icon-quote-left"></i>
                    </span>
                  </div>
                  <div class="text text-center">
                    <p class="mb-5">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                    <p class="name">Dennis Green</p>
                    <span class="position">Web Developer</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="testimony-wrap p-4 pb-5">
                  <div class="user-img mb-5" style="background-image: url(images/person_1.jpg)">
                    <span class="quote d-flex align-items-center justify-content-center">
                      <i class="icon-quote-left"></i>
                    </span>
                  </div>
                  <div class="text text-center">
                    <p class="mb-5">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                    <p class="name">Dennis Green</p>
                    <span class="position">System Analytics</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->

  <!-- <section class="ftco-section-parallax">
      <div class="parallax-img d-flex align-items-center">
        <div class="container">
          <div class="row d-flex justify-content-center">
            <div class="col-md-7 text-center heading-section heading-section-white ftco-animate">
              <h2>Subcribe to our Newsletter</h2>
              <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in</p>
              <div class="row d-flex justify-content-center mt-5">
                <div class="col-md-8">
                  <form action="#" class="subscribe-form">
                    <div class="form-group d-flex">
                      <input type="text" class="form-control" placeholder="Enter email address">
                      <input type="submit" value="Subscribe" class="submit px-3">
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>-->

    <footer class="ftco-footer ftco-bg-dark ftco-section">
      <div class="container">
        <div class="row mb-5">
          <div class="col-md-4">
            <div class="ftco-footer-widget mb-4">
              <h2 class="ftco-heading-2 text-left">Woodsman</h2>
              <p>
                Woodsman has been established in Dec 2021 with a vision to be
                one of the top 10 IOT Solution Providers in Asia by 2026.
              </p>
            </div>
            <!--  <ul class="ftco-footer-social list-unstyled float-md-left float-lft ">
                <li class="ftco-animate"><a href="#"><span class="icon-twitter"></span></a></li>
                <li class="ftco-animate"><a href="#"><span class="icon-facebook"></span></a></li>
                <li class="ftco-animate"><a href="#"><span class="icon-instagram"></span></a></li>
              </ul>-->
          </div>
          <div class="col-md-4">
            <div class="ftco-footer-widget mb-4 ml-md-5">
              <h2 class="ftco-heading-2 text-left">Quick Links</h2>
              <ul class="list-unstyled text-left">
                
                <li class="list-item">
                  <a href="IOT-platform" class="py-2 d-block">Platform</a>
                </li>
                <li class="list-item">
                  <a href="solutions" class="py-2 d-block">Solutions</a>
                </li>
                <li class="list-item">
                  <a href="coe" class="py-2 dblock">Center Of Excellence</a>
                </li>
                <li class="list-item">
                  <a href="career" class="py-2 d-block">Career</a>
                </li>
                <li class="list-item">
                  <a href="contact" class="py-2 d-block">Contact</a>
                </li>
              </ul>
            </div>
          </div>
  
          <div class="col-md-4">
            <div class="ftco-footer-widget mb-4">
              <h2 class="ftco-heading-2 text-left">Contact Info</h2>
              <div class="block-23 mb-3">
                <ul>
                  <li>
                    <span class="icon icon-map-marker"></span><span class="text">Woodsman Smart Retail Solutions LLP<br />
                      Bangalore, India</span>
                  </li>
                  <li>
                    <a href="#"><span class="icon icon-phone"></span><span class="text">+91 99809 36856</span></a>
                  </li>
                  <li>
                    <a href="#"><span class="icon icon-envelope"></span><span
                        class="text">info@woodsmanglobal.com</span></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="row">
        <div class="col-md-12 text-center">
          <p>
            <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
            Copyright &copy;
            <script>
              document.write(new Date().getFullYear());
            </script>
            <a href="" target="_blank">Woodsman Smart Retail Solutions LLP</a>
            <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
          </p>
        </div>
      </div>
    </footer>

  <!-- loader -->
  <!-- <div id="ftco-loader" class="show fullscreen"><svg class="circular" width="48px" height="48px">
            <circle class="path-bg" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke="#eeeeee" />
            <circle class="path" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke-miterlimit="10"
                stroke="#F96D00" />
        </svg></div> -->

  <!-- Modal -->
  <div class="modal fade" id="modalRequest" tabindex="-1" role="dialog" aria-labelledby="modalRequestLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalRequestLabel">Request a Quote</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="form-group">
              <!-- <label for="appointment_name" class="text-black">Full Name</label> -->
              <input type="text" class="form-control" id="appointment_name" placeholder="Full Name" />
            </div>
            <div class="form-group">
              <!-- <label for="appointment_email" class="text-black">Email</label> -->
              <input type="text" class="form-control" id="appointment_email" placeholder="Email" />
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <!-- <label for="appointment_date" class="text-black">Date</label> -->
                  <input type="text" class="form-control" id="appointment_date" placeholder="Date" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <!-- <label for="appointment_time" class="text-black">Time</label> -->
                  <input type="text" class="form-control" id="appointment_time" placeholder="Time" />
                </div>
              </div>
            </div>

            <div class="form-group">
              <!-- <label for="appointment_message" class="text-black">Message</label> -->
              <textarea name="" id="appointment_message" class="form-control" cols="30" rows="10"
                placeholder="Message"></textarea>
            </div>
            <div class="form-group">
              <input type="submit" value="Get a Quote" class="btn btn-primary" />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <script src="../resources/js/jquery.min.js"></script>
  <script src="../resources/js/jquery-migrate-3.0.1.min.js"></script>
  <script src="../resources/js/popper.min.js"></script>
  <script src="../resources/js/bootstrap.min.js"></script>
  <script src="../resources/js/jquery.easing.1.3.js"></script>
  <script src="../resources/js/jquery.waypoints.min.js"></script>
  <script src="../resources/js/jquery.stellar.min.js"></script>
  <script src="../resources/js/owl.carousel.min.js"></script>
  <script src="../resources/js/jquery.magnific-popup.min.js"></script>
  <script src="../resources/js/aos.js"></script>
  <script src="../resources/js/jquery.animateNumber.min.js"></script>
  <script src="../resources/js/bootstrap-datepicker.js"></script>
  <script src="../resources/js/jquery.timepicker.min.js"></script>
  <script src="../resources/js/scrollax.min.js"></script>
  <script
    src="../resources/https://maps.googleapis.com/maps/api/js?key=AIzaSyBVWaKrjvy3MaE7SQ74_uJiULgl1JY0H2s&sensor=false"></script>
  <script src="../resources/js/google-map.js"></script>
  <script src="../resources/js/main.js"></script>
</body>

</html>