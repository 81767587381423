import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-psolution',
  templateUrl: './psolution.component.html',
  styleUrls: ['./psolution.component.scss']
})
export class PsolutionComponent implements OnInit {
  isDropdownOpen: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }
  onMouseEnter(event: Event): void {
    const element = event.target as HTMLElement;
    this.isDropdownOpen = true;
    element.setAttribute('aria-expanded', 'true');
  }

  onMouseLeave(event: Event): void {
    const element = event.target as HTMLElement;
    this.isDropdownOpen = false;
    element.setAttribute('aria-expanded', 'false');
  }
}
