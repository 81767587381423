<div style="height: 60px;">

</div>
<div class="container">
    <!-- Display Grid for Extra Small Devices (xs) -->
    <div fxHide.lt-md>
        <div style="height: 40px;">

        </div>
        <mat-card fxLayout="row" fxLayoutAlign="start center" style="padding: 0px;border-bottom: 1px solid black;border-left: 1px solid black;">
            <div fxFlex="20" fxLayout="row" fxLayoutAlign="center center" style="padding: 10px;border-top: 1px solid black;border-right: 1px solid black;">
                
                <button mat-button><span><span>Shopping bag</span> (9)</span></button>
            </div>
            <div fxFlex="20" fxLayout="row" fxLayoutAlign="center center" style="padding: 10px;border-top: 1px solid black;border-right: 1px solid black;">
     
            <button mat-button>Favourites<mat-icon style="font-size: 16px;">bookmark_border</mat-icon></button>

            </div>
        </mat-card>
        <div style="height: 40px;">

        </div>
        <mat-card class="sticky-card" style="padding: 0px;" >
    
            <mat-card-content >
                <div fxFlex="80" fxLayout="row" fxLayoutAlign="start center" style="padding: 20px;">
                    <div >
                        * By continuing, I declare that I have read and accept the Purchase Conditions and understand Zara's Privacy and Cookie Policy.
                    </div>

                </div>
 
        
                <div fxFlex="10" fxLayout="column" fxLayoutAlign="center end" style="padding: 20px;">
        
                    <div>TOTAL  ₹ 21,660.00</div>
                    <div>Including GST</div>
                    <div>* excl Shipping cost</div>
                </div>
                <div fxFlex="10" style="background-color: black;color: white;">
                    <button mat-button style="height: 100%;width: 100%;">CONTINUE</button>
                </div>
            </mat-card-content>
     
        </mat-card>
        <mat-grid-list cols="5" rowHeight="1:1.5">
            <mat-grid-tile *ngFor="let product of products">
                <mat-card fxLayout="column" (click)="onClick({ item: product, label: product })" class="example-card" style="padding: 0; height: 100%; width: 100%; display: flex; flex-direction: column;">
                    <div fxFlex="75" style="overflow: hidden;">
                        <img fxFlex="100" style="width: 100%; height: auto;" src="https://static.zara.net/assets/public/387f/3a88/d82045a5bbb1/0d648cada0db/06085418529-a1/06085418529-a1.jpg" >
                    </div>
                    <div fxFlex="25" style="padding: 10px; border-top: 1px solid black;">
                        <div fxLayout="column" fxLayoutAlign="start stretch">
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <div fxFlex="80">
                                    {{ product.name }}
                                </div>
                                <div fxFlex="20" fxLayoutAlign="end center">
                                    <mat-icon style="font-size: 19px;">clear</mat-icon>
                                </div>
                            </div>
                            
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <div fxFlex="100">
                                    {{ product.price }}
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center" style="margin-top: 7px;margin-bottom: 7px;">
                                <div fxFlex="100">
                                    <span style="margin-right: 5px;">
                                        {{ product.size }}
                                    </span>
                                    |
                                    <span style="margin-left: 5px;">
                                        {{ product.color }}
                                    </span>
                                     
                                </div>
                            </div>
                            <div  fxLayout="row" fxLayoutAlign="start center" >
                                <div fxFlex="100" style="border-left: 1px solid black;">
                                    <button mat-icon-button style="border-top: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;border-radius: 0px;"><mat-icon>remove</mat-icon></button>
                                    <button mat-icon-button style="border-top: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;border-radius: 0px;">1</button>
                                    <button mat-icon-button style="border-top: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;border-radius: 0px;"><mat-icon>add</mat-icon></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </mat-grid-tile>
        </mat-grid-list>
        <div style="height: 100px;">

        </div>
    </div>

    <!-- Display Grid for Small Devices (gt-xs) -->
    <div fxHide.gt-xs>
  
        <mat-card fxLayout="row" fxLayoutAlign="start center" style="padding: 0px;border-bottom: 1px solid black;border-left: 1px solid black;">
            <div fxFlex="50" fxLayout="row" fxLayoutAlign="center center" style="padding: 10px;border-top: 1px solid black;border-right: 1px solid black;">
                
                <button mat-button><span><span>Shopping bag</span> (9)</span></button>
            </div>
            <div fxFlex="50" fxLayout="row" fxLayoutAlign="center center" style="padding: 10px;border-top: 1px solid black;border-right: 1px solid black;">
     
            <button mat-button>Favourites<mat-icon style="font-size: 16px;">bookmark_border</mat-icon></button>

            </div>
        </mat-card>
        <div style="height: 40px;">

        </div>

         
        <mat-card class="sticky-card" style="padding: 0px;" >
    
            <mat-card-content >
                <div fxFlex="50" style="background-color: black;color: white;">
                    <button mat-button style="height: 100%;width: 100%;">CONTINUE</button>
                </div>
        
                <div fxFlex="50" fxLayout="column" fxLayoutAlign="center end" style="padding: 20px;">
        
                    <div>TOTAL  ₹ 21,660.00</div>
                    <div>Including GST</div>
                    <div>* excl Shipping cost</div>
                </div>
            </mat-card-content>
     
        </mat-card>
          
        <mat-grid-list cols="2" rowHeight="1:1.5" style="position: static;">
            <ng-container *ngFor="let product of products">
                <mat-grid-tile>
                    <mat-card fxLayout="column" (click)="onClick({ item: product, label: product })" class="example-card" style="padding: 0; height: 100%; width: 100%; display: flex; flex-direction: column;">
                        <div fxFlex="100" style="overflow: hidden;">
                            <img fxFlex="100" style="width: 100%; height: auto;" src="https://static.zara.net/assets/public/387f/3a88/d82045a5bbb1/0d648cada0db/06085418529-a1/06085418529-a1.jpg" >
                        </div>
     
                    </mat-card>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-card fxLayout="column" (click)="onClick({ item: product, label: product })" class="example-card" style="padding: 0; height: 100%; width: 100%; display: flex; flex-direction: column;">
         
                        <div fxFlex="100" style="padding: 10px; border-top: 1px solid black; font-size: 10px;">
                            <div fxLayout="column" fxLayoutAlign="space-between stretch" style="height: 100%;">
                                <div>
                                    <div  fxLayout="row" fxLayoutAlign="start center">
                                        <div fxFlex="80">
                                        </div>
                                        <div fxFlex="20" fxLayoutAlign="end center">
                                            <mat-icon style="font-size: 16px;">clear</mat-icon>
                                        </div>
                                    </div>
                                    <div  fxLayout="row" fxLayoutAlign="start center">
                                        <div fxFlex="80">
                                            {{ product.name }}
                                        </div>
                                        <div fxFlex="20" fxLayoutAlign="end center">
                                        </div>
                                    </div>
                      
                                    <div fxLayout="row" fxLayoutAlign="start center">
                                        <div fxFlex="100">
                                            {{ product.price }}
                                        </div>
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="start center" style="margin-top: 7px;margin-bottom: 7px;font-size: 12px;">
                                        <div fxFlex="100">
                                            <span style="margin-right: 5px;">
                                                {{ product.size }}
                                            </span>
                                            |
                                            <span style="margin-left: 5px;">
                                                {{ product.color }}
                                            </span>
                                             
                                        </div>
                                    </div>
                                </div>
   
                                <div  fxLayout="row" fxLayoutAlign="start center" >
                                    <div fxFlex="100" style="border-left: 1px solid black;">
                                        <button mat-icon-button style="border-top: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;border-radius: 0px;"><mat-icon>remove</mat-icon></button>
                                        <button mat-icon-button style="border-top: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;border-radius: 0px;">1</button>
                                        <button mat-icon-button style="border-top: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;border-radius: 0px;"><mat-icon>add</mat-icon></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </mat-grid-tile>
            </ng-container>

            <!-- <mat-grid-tile *ngFor="let product of products" >
  
                <mat-card fxLayout="column" (click)="onClick({ item: product, label: product })" class="example-card" style="padding: 0; height: 100%; width: 100%; display: flex; flex-direction: column;">
                    <div fxFlex="80" style="overflow: hidden;">
                        <img fxFlex="100" style="width: 100%; height: auto;" src="https://static.zara.net/assets/public/387f/3a88/d82045a5bbb1/0d648cada0db/06085418529-a1/06085418529-a1.jpg" >
                    </div>
                    <div fxFlex="20" style="padding: 10px; border-top: 1px solid black; font-size: 10px;">
                        <div fxLayout="column" fxLayoutAlign="start stretch">
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <div fxFlex="80">
                                    {{ product.name }}
                                </div>
                                <div fxFlex="20" fxLayoutAlign="end center">
                                    <mat-icon style="font-size: 16px;">bookmark_border</mat-icon>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <div fxFlex="100">
                                    {{ product.price }}
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </mat-grid-tile> -->
        </mat-grid-list>
        <div style="height: 100px;">

        </div>
    </div>
</div>
