<!DOCTYPE html>
<html lang="en">

<head>
  <link rel="icon" href="/assets/resources/images/logo/favicon.ico" type="img" sizes="16x16" />
  <title>Home | Woodsman Smart Retail Solutions LLP</title>
  <meta charset="utf-8" />
  <!-- <meta  -->
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
  <meta name="keywords" content="platform,internet of things,iot,iot-platform,preva,preva-systems,ios-solutions,industry-solutions,iotapplications,development,services,mobile-control
    ,tv,electronics,education,transport,introduction of iot,features" />

  <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700" rel="stylesheet" />
  <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css"
    integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossorigin="anonymous" />

  <link rel="stylesheet" href="/assets/resources/css/open-iconic-bootstrap.min.css" />
  <link rel="stylesheet" href="/assets/resources/css/animate.css" />

  <link rel="stylesheet" href="/assets/resources/css/owl.carousel.min.css" />
  <link rel="stylesheet" href="/assets/resources/css/owl.theme.default.min.css" />
  <link rel="stylesheet" href="/assets/resources/css/magnific-popup.css" />

  <link rel="stylesheet" href="/assets/resources/css/aos.css" />

  <link rel="stylesheet" href="/assets/resources/css/ionicons.min.css" />

  <link rel="stylesheet" href="/assets/resources/css/bootstrap-datepicker.css" />
  <link rel="stylesheet" href="/assets/resources/css/jquery.timepicker.css" />

  <link rel="stylesheet" href="/assets/resources/css/flaticon.css" />
  <link rel="stylesheet" href="/assets/resources/css/icomoon.css" />
  <link rel="stylesheet" href="/assets/resources/css/style.css" />
  <!-- <link rel="stylesheet" href="css/style.css" /> -->
</head>

<body>
  <nav class="navbar navbar-expand-lg navbar-dark ftco_navbar bg-ligh ftco-navbar-light" id="ftco-navbar">
    <div class="container">
        <a class="navbar-brand" href=""><img src="/assets/resources/images/logo/LOGO.png" /></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav"
            aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="oi oi-menu"></span> Menu
        </button>

        <div class="collapse navbar-collapse" id="ftco-nav">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item dropdown">
                <a href="shop" class="nav-link">Shop</a>
              </li>
                <li class="nav-item">
                    <a href="IOT-platform" class="nav-link">Platform</a>
                </li>
                <li class="nav-item dropdown" (mouseenter)="onMouseEnter($event)" (mouseleave)="onMouseLeave($event)">
                    <a class="nav-link" [attr.aria-expanded]="isDropdownOpen" href="solutions">Solutions</a>
                    <ul class="dropdown-menu" [class.show]="isDropdownOpen">
                      <li><a href="solutions/asset-management">ASSET MANAGEMENT</a></li>
                      <div class="dropdown-divider"></div>
                      <li><a href="solutions/event-management">EVENT MANAGEMENT</a></li>
                      <div class="dropdown-divider"></div>
                      <li>
                        <a href="solutions/health-care-management">HEALTHCARE MANAGEMENT</a>
                      </li>
                      <div class="dropdown-divider"></div>
                      <li>
                        <a href="solutions/library-management">LIBRARY MANAGEMENT</a>
                      </li>
          
                      <div class="dropdown-divider"></div>
                      <li>
                        <a href="solutions/tracking-management">TRACKING MANAGEMENT</a>
                      </li>
                    </ul>
                  </li>

                <li class="nav-item"><a class="nav-link" href="coe">Coe</a></li>
                <li class="nav-item">
                    <a href="career" class="nav-link">Career</a>
                </li>
                <li class="nav-item">
                    <a href="contact" class="nav-link">Contact</a>
                </li>
            </ul>
        </div>
    </div>
</nav>
  <!-- END nav -->

  <div class="hero-wrap">
    <!-- <div class="overlay"></div>-->
    <div class="container-fluid">
      <div class="slider-text d-md-flex align-items-center" data-scrollax-parent="true">
        <div class="one-forth ftco-animate align-self-md-center" data-scrollax=" properties: { translateY: '70%' }">
          <h1 class="mb-4">
            Woodsman IOT Platform
            <strong class="typewrite" data-period="4000" data-type="[  ]">
              <span class="wrap"></span>
            </strong>
          </h1>
          <p class="mb-md-5 mb-sm-3" data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"
            style="color: black">
            Woodsman has been established in Dec 2021 with a vision to be one
            of the top 10 IOT Solution Providers in Asia by 2026.
          </p>
          <!--<p data-scrollax="properties: { translateY: '30%', opacity: 1.6 }"><a href="#" class="btn btn-primary px-4 py-3">Get a Quote</a></p>-->
        </div>

        <div class="one-half align-self-md-start align-self-sm-center">
          <div class="slider-container" style="background-color: transparent;height: fit-content;">
            <div class="slider">
              <img [src]="images[currentIndex]" alt="Slider image" class="slider-image"  style="position: relative;height: 500px;width: fit-content;"/>
            </div>
            <!-- <button class="prev" (click)="prevSlide()">&#10094;</button>
            <button class="next" (click)="nextSlide()">&#10095;</button> -->
          </div>
          
          <div class="container">
            <!-- <img src="/assets/resources/images/logo/iot2.png" alt=" iot_banner" style="position: relative" /> -->
            <!-- <div class="slider-carousel owl-carousel">
              <div class="item">
                
              </div>
              <div class="item">
                <img src="/assets/resources/images/logo/cloudofthingsv2.png" alt="network" style="position: relative" />
              </div>
              <div class="item">
                <img src="/assets/resources/images/logo/IoT_png.png" alt="banner" style="position: relative" />
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <section class="ftco-section ftco-section-2">
    <div class="overlay"></div>
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <h3 class="text-center">
            <a href="about-us/our-customer.html" class="heading-white outline-primary">Customer Foot print</a>
          </h3>
        </div>
      </div>
    </div>
  </section>

  <section class="ftco-section ftco-services">
    <div class="container">
      <div class="row justify-content-center mb-5 pb-5">
        <div class="col-md-7 text-center heading-section ftco-animate">
          <h2 class="mb-2">WELCOME TO WOODSMAN</h2>
          <!--<span class="subheading">Search Engine &amp; Social Media Optimization Experts</span>-->
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 d-flex align-self-stretch ftco-animate">
          <div class="media block-6 services d-block text-center">
            <div class="d-flex justify-content-center">
              <div class="icon">
                <img src="/assets/resources/images/icons/overview.png" alt="" />
              </div>
            </div>
            <div class="media-body p-2 mt-3">
              <h3 class="heading">OVERVIEW</h3>
              <p>
                Woodsman offers end to end solutions in the area of real time
                identification, location and mobility of things/assets using
                its IoT platform enriched with analytics for superior business
                results.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 d-flex align-self-stretch ftco-animate">
          <div class="media block-6 services d-block text-center">
            <div class="d-flex justify-content-center">
              <div class="icon">
                <img src="/assets/resources/images/icons/integration-512.png" alt="" />
              </div>
            </div>
            <div class="media-body p-2 mt-3">
              <h3 class="heading">PLATFORM</h3>
              <p>
                Woodsman industry standards of IoT platform opens a new vistas
                in communication making it versatile flexible and scalable.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 d-flex align-self-stretch ftco-animate">
          <div class="media block-6 services d-block text-center">
            <div class="d-flex justify-content-center">
              <div class="icon">
                <img src="/assets/resources/images/icons/innovation.png" alt="" />
              </div>
            </div>
            <div class="media-body p-2 mt-3">
              <h3 class="heading">SOLUTION</h3>
              <p>
                Woodsman develops Real Time Material Tracking & Management
                Solutions for Industries & Enterprises using its IoT Platform.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-wrap mt-5">
      <div class="row d-flex no-gutters">
        <div class="col-md-6 img ftco-animate fadeInRight">
          <img src="/assets/resources/images/dash/internet-of-things-IoT.png" alt="" class="" />
        </div>
        <div class="col-md-6 d-flex">
          <div class="services-wrap">
            <div class="heading-section mb-5 ftco-animate">
              <h2 class="mb-2">OUR SOLUTIONS FOR THE SMART ENTERPRISE</h2>
              <!-- <span class="subheading">Developing &amp;Providing IoT services for</span>-->
            </div>
            <div class="list-services d-flex ftco-animate">
              <div class="icon d-flex justify-content-center align-items-center">
                <span class="icon-industry"></span>
              </div>
              <div class="text">
                <h3>Industries & Manufacturing :</h3>
                <p>
                  Material & machine tracking and management, worker tracking,
                  shipment tracking, production output tracking and field
                  force tracking.
                </p>
                <p>
                  <strong>Technologies: </strong>GPS, GPRS, RFID, BLE,
                  MODBUS/OPC-UA, Wireless Sensors.
                </p>
              </div>
            </div>

            <div class="list-services d-flex ftco-animate">
              <div class="icon d-flex justify-content-center align-items-center">
                <span class="icon-car"></span>
              </div>
              <div class="text">
                <h3>Telematics & Transport :</h3>
                <p>
                  Fleet Management, goods tracking, temperature tracking for
                  cold storage vehicles, fuel tracking and container tracking,
                  vehicle maintenance tracking, vehicle health parameters
                  tracking.
                </p>
                <p>
                  <strong>Technologies:</strong> GPS, GPRS, RFID, DVR/CCTV,
                  Sensors.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="ftco-section ftco-counter img" id="section-counter" style="background-image: url(images/bg_1.jpg)"
    data-stellar-background-ratio="0.5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-11">
          <div class="row">
            <!--<div class="col-md-3 d-flex justify-content-center counter-wrap ftco-animate">
		            <div class="block-18 text-center">
		             <div class="text">
		                <strong class="number" data-number="6780">0</strong>
		                <span>HAPPY CLIENTS</span>
		              </div> 
		            </div>
		          </div>-->
            <div class="col-md-4 d-flex justify-content-center counter-wrap ftco-animate">
              <div class="block-18 text-center">
                <div class="text">
                  <strong class="number" data-number="40000">0 </strong>
                  <span>CONNECTED THINGS</span>
                </div>
              </div>
            </div>
            <div class="col-md-4 d-flex justify-content-center counter-wrap ftco-animate">
              <div class="block-18 text-center">
                <div class="text">
                  <strong class="number" data-number="10000000">0 </strong>
                  <span>TRANSACTIONS PER DAY</span>
                </div>
              </div>
            </div>
            <div class="col-md-4 d-flex justify-content-center counter-wrap ftco-animate">
              <div class="block-18 text-center">
                <div class="text">
                  <strong class="number" data-number="70000">0 </strong>
                  <span>NOTIFICATIONS PER DAY</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <br />

  <!--<section class="ftco-animate">
    <div class="container-fluid">
        <div class="row d-flex no-gutters">
          
          <div class="col-md-6 d-flex">
              <div class="services-wrap">
                  <nav>
                      <div class="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
                        <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true" onclick="document.getElementById('image1').src='resources/images/bg/IMG-20180213-WA0047.jpg'">NEWS</a>
                        <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false" onclick="document.getElementById('image1').src='resources/images/bg/IMG_20161118_154551.jpg'">EVENTS</a>
                        <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false" onclick="document.getElementById('image1').src='resources/images/bg/iotcongress.jpg'">AWARDS</a>
                      </div>
                    </nav>
                
                    <div class="tab-content" id="nav-tabContent">
                      <div class="tab-pane fade show active " id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                          
                        <h5><i class="fas fa-arrow-circle-right" style="color:#2f89fc"></i> UberEXCHANGE Program 2018</h5>
                     <p>Woodsman Pvt ltd has been shortlisted Top 10 Start-ups by UberEXCHANGE – Start-up Mentorship Program ,2018</p>
                     <h5><i class="fas fa-arrow-circle-right" style="color:#2f89fc"></i> IoT India Congress 2017</h5>
                     <p>Woodsman Pvt ltd has been shortlisted among the Top 12 IoT Start-ups by IoT India Congress,2017</p>
                     <p>Sharan K, MD, Woodsman at IoT India Congress 2017</p>
                     <h5> <i class="fas fa-arrow-circle-right" style="color:#2f89fc"></i> IoT Awareness session</h5>
                     <p>Awareness session by Woodsman on "Application of Arduino in engineering fields" at SVERI's College of Engineering, Pandharpur.</p>
                     <h5><i class="fas fa-arrow-circle-right" style="color:#2f89fc"></i> IoT Workshop</h5>
                     <p>One day National Level SDP on IoT at Rajarajeswari College of Engineering, Bangalore.</p>
                     

                     </div>
                    
                      <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                          <p>-- Conducted one day Boot camp on IoT at Vellore Institute of Technology, Vellore.
                              Feb 09, 2018</p>
                              <p>-- Conducted 5 days FDP on IoT organised by, ESDM cluster at BVB college of engineering, Hubballi.
                                  July 24, 2017</p>
                   <p>-- Preva CEO participated in "The Biggest IoT Security Threats, and How do we Tackle Them", a panel discussion at Park Plaza, Bangalore during RISC - 2017 on 20th June organized by Electronics For You.
                      June 20, 2017</p> 
                    <p>--	Organised 3 day IoT Bootcamp at RITE, Bhubaneshwar.
                        March 22, 2017</p>
                    <p>--	Preva conducts its first IoT platform Workshop - How to choose the right IoT platform for your IoT solution organized by IESA & TiE, Bangalore.
                        Dec 26, 2016</p>                    
                                        
              
              
              
                      </div>
                      <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                        <h5><i class="fas fa-trophy fa-2x " style="color:#2f89fc"></i> CEATEC-Japan</h5>
                          <p>One day National Level SDP on IoT at Rajarajeswari College of Engineering, Bangalore.</p>
                          <h5><i class="fas fa-trophy fa-2x" style="color:#2f89fc"></i> IoT Congress-Bangalore</h5>
                          <p>Top 10 IoT Start Up Companies, IoT Congress, Bangalore Sep 2016</p>
                          <h5><i class="fas fa-trophy fa-2x" style="color:#2f89fc"></i> IESA Technovation Awards</h5>
                          <p>IESA Technovation Awards : Most promising Start Up 2015</p>
                          <h5><i class="fas fa-trophy fa-2x" style="color:#2f89fc"></i>
                               Intel – TiE</h5>
                          <p>Top 7 IoT Start Up Companies – Healthcare, Intel – TiE, Bangalore 2014</p>
              
                          
                      </div>
                      
                    </div>
                    
                  </div>
                  </div>
          
                  <div class="col-md-6">
                    <img src="/assets/resources/images/bg/IMG-20180213-WA0047.jpg" alt="" id="image1" width="70%" height="auto;" class="animated fadeInRight">
                    
                    
                    </div>
                    
                

</section>-->
  <!--<section class="ftco-section ftco-work">
    	<div class="container-fluid">
    		<div class="row justify-content-center mb-5 pb-5">
          <div class="col-md-7 text-center heading-section ftco-animate">
            <h2 class="mb-2">Our Portfolio</h2>
            <span class="subheading">We're Happy to share our complete Projects</span>
          </div>
        </div>
        <div class="row">
        	<div class="col-md-4 ftco-animate">
        		<div class="work-entry">
	        		<a href="#" class="img" style="background-image: url(images/work-1.jpg);">
	        			<div class="text d-flex justify-content-center align-items-center">
		        			<div class="p-3">
		        				<span>Branding</span>
		        				<h3>Work 01</h3>
		        			</div>
	        			</div>
	        		</a>
        		</div>
        	</div>
        	<div class="col-md-4 ftco-animate">
        		<div class="work-entry">
	        		<a href="#" class="img" style="background-image: url(images/work-2.jpg);">
	        			<div class="text d-flex justify-content-center align-items-center">
		        			<div class="p-3">
		        				<span>Branding</span>
		        				<h3>Work 02</h3>
		        			</div>
	        			</div>
	        		</a>
        		</div>
        	</div>
        	<div class="col-md-4 ftco-animate">
        		<div class="work-entry">
	        		<a href="#" class="img" style="background-image: url(images/work-3.jpg);">
	        			<div class="text d-flex justify-content-center align-items-center">
		        			<div class="p-3">
		        				<span>Branding</span>
		        				<h3>Work 03</h3>
		        			</div>
	        			</div>
	        		</a>
        		</div>
        	</div>
        	<div class="col-md-4 ftco-animate">
        		<div class="work-entry">
	        		<a href="#" class="img" style="background-image: url(images/work-4.jpg);">
	        			<div class="text d-flex justify-content-center align-items-center">
		        			<div class="p-3">
		        				<span>Branding</span>
		        				<h3>Work 01</h3>
		        			</div>
	        			</div>
	        		</a>
        		</div>
        	</div>
        	<div class="col-md-4 ftco-animate">
        		<div class="work-entry">
	        		<a href="#" class="img" style="background-image: url(images/work-5.jpg);">
	        			<div class="text d-flex justify-content-center align-items-center">
		        			<div class="p-3">
		        				<span>Branding</span>
		        				<h3>Work 02</h3>
		        			</div>
	        			</div>
	        		</a>
        		</div>
        	</div>
        	<div class="col-md-4 ftco-animate">
        		<div class="work-entry">
	        		<a href="#" class="img" style="background-image: url(images/work-6.jpg);">
	        			<div class="text d-flex justify-content-center align-items-center">
		        			<div class="p-3">
		        				<span>Branding</span>
		        				<h3>Work 03</h3>
		        			</div>
	        			</div>
	        		</a>
        		</div>
        	</div>
        </div>
    	</div>
    </section>

    <section class="ftco-section bg-light">
    	<div class="container">
    		<div class="row justify-content-center mb-5 pb-5">
          <div class="col-md-7 text-center heading-section ftco-animate">
            <h2 class="mb-3">Our Best Pricing</h2>
            <span class="subheading">Pricing Plans</span>
          </div>
        </div>
    		<div class="row">
	        <div class="col-md-3 ftco-animate">
	          <div class="block-7">
	            <div class="text-center">
	            <h2 class="heading">Free</h2>
	            <span class="price"><sup>$</sup> <span class="number">0</span></span>
	            <span class="excerpt d-block">100% free. Forever</span>
	            <a href="#" class="btn btn-primary d-block px-2 py-3 mb-4">Get Started</a>
	            
	            <h3 class="heading-2 mb-4">Enjoy All The Features</h3>
	            
	            <ul class="pricing-text">
	              <li><strong>150 GB</strong> Bandwidth</li>
	              <li><strong>100 GB</strong> Storage</li>
	              <li><strong>$1.00 / GB</strong> Overages</li>
	              <li>All features</li>
	            </ul>
	            </div>
	          </div>
	        </div>
	        <div class="col-md-3 ftco-animate">
	          <div class="block-7">
	            <div class="text-center">
	            <h2 class="heading">Startup</h2>
	            <span class="price"><sup>$</sup> <span class="number">19</span></span>
	            <span class="excerpt d-block">All features are included</span>
	            <a href="#" class="btn btn-primary btn-outline-primary d-block px-3 py-3 mb-4">Get Started</a>
	            
	            <h3 class="heading-2 mb-4">Enjoy All The Features</h3>
	            
	            <ul class="pricing-text">
	              <li><strong>450 GB</strong> Bandwidth</li>
	              <li><strong>400 GB</strong> Storage</li>
	              <li><strong>$2.00 / GB</strong> Overages</li>
	              <li>All features</li>
	            </ul>
	            </div>
	          </div>
	        </div>
	        <div class="col-md-3 ftco-animate">
	          <div class="block-7">
	            <div class="text-center">
	            <h2 class="heading">Premium</h2>
	            <span class="price"><sup>$</sup> <span class="number">49</span></span>
	            <span class="excerpt d-block">All features are included</span>
	            <a href="#" class="btn btn-primary btn-outline-primary d-block px-3 py-3 mb-4">Get Started</a>
	            
	            <h3 class="heading-2 mb-4">Enjoy All The Features</h3>
	            
	            <ul class="pricing-text">
	              <li><strong>250 GB</strong> Bandwidth</li>
	              <li><strong>200 GB</strong> Storage</li>
	              <li><strong>$5.00 / GB</strong> Overages</li>
	              <li>All features</li>
	            </ul>
	            </div>
	          </div>
	        </div>
	        <div class="col-md-3 ftco-animate">
	          <div class="block-7">
	            <div class="text-center">
	            <h2 class="heading">Pro</h2>
	            <span class="price"><sup>$</sup> <span class="number">99</span></span>
	            <span class="excerpt d-block">All features are included</span>
	            <a href="#" class="btn btn-primary btn-outline-primary d-block px-3 py-3 mb-4">Get Started</a>
	            
	            <h3 class="heading-2 mb-4">Enjoy All The Features</h3>
	            
	            <ul class="pricing-text">
	              <li><strong>450 GB</strong> Bandwidth</li>
	              <li><strong>400 GB</strong> Storage</li>
	              <li><strong>$20.00 / GB</strong> Overages</li>
	              <li>All features</li>
	            </ul>
	            </div>
	          </div>
	        </div>
	      </div>
    	</div>
    </section>-->

  <!--<section class="ftco-quote">
    	<div class="container">
    		<div class="row">
    			<div class="col-md-6 pr-md-5 aside-stretch py-5 choose">
    				<div class="heading-section heading-section-white mb-5 ftco-animate">
	            <h2 class="mb-2">News</h2>
	          </div>
	          <div class="ftco-animate">
	          	<p>Woodsman has been established in Dec 2021 with a vision to be one of the top 10 IOT Solution Providers in Asia by 2026. Woodsman develops Real Time  Material Tracking & Management Solutions for Industries & Enterprises, based on their flagship IOT Cloud platform 
                 is buit for:</p>
	          	<ul class="un-styled my-5">
	          		<li><span class="icon-check"></span>UberEXCHANGE Program 2018</a><br>Systems Pvt ltd has been shortlisted Top 10 Start-ups by UberEXCHANGE – Start-up Mentorship Program ,2018.</li>
                <li><span class="icon-check"></span>Woodsman Pvt ltd has been shortlisted among the Top 12 IoT Start-ups by IoT India Congress,2017.</li>
                <li><span class="icon-check"></span>Sharan K, MD, Woodsman at IoT India Congress 2017.</li>
                <li><span class="icon-check"></span>Awareness session by Woodsman on "Application of Arduino in engineering fields" at SVERI's College of Engineering, Pandharpur.</li>
                <li><span class="icon-check"></span>One day National Level SDP on IoT at Rajarajeswari College of Engineering, Bangalore.</li>
	          		<li><span class="icon-check"></span>Tempore reprehenderit vitae</li>
	          	</ul>
	          </div>
    			</div>
    			<div class="col-md-6 py-5 pl-md-5">
    				<div class="heading-section mb-5 ftco-animate">
	            <h2 class="mb-2">Events</h2>
	          </div>
	          <div class="ftco-animate">
             <p><span class="icon-check"></span>  Conducted one day Boot camp on IoT at Vellore Institute of Technology, Vellore.
                Feb 09, 2018</p>
                <p><span class="icon-check"></span>  Conducted 5 days FDP on IoT organised by, ESDM cluster at BVB college of engineering, Hubballi.
                    July 24, 2017</p>
                   <p><span class="icon-check"></span> Preva CEO participated in "The Biggest IoT Security Threats, and How do we Tackle Them", a panel discussion at Park Plaza, Bangalore during RISC - 2017 on 20th June organized by Electronics For You.
                      June 20, 2017</p> 
                     <p><span class="icon-check"></span>Organised 3 day IoT Bootcamp at RITE, Bhubaneshwar.
                        March 22, 2017</p> 
                        <p>	<span class="icon-check"></span> Preva conducts its first IoT platform Workshop - How to choose the right IoT platform for your IoT solution organized by IESA & TiE, Bangalore.
                            Dec 26, 2016</p>


            </div>
    			</div>
    		</div>
    	</div>
    </section>-->

  <!--	<section class="ftco-section testimony-section bg-light">
      <div class="container">
        <div class="row justify-content-center mb-5 pb-3">
          <div class="col-md-7 text-center heading-section ftco-animate">
            <h2 class="mb-2">Testimony</h2>
            <span class="subheading">Our Happy Customer Says</span>
          </div>
        </div>
        <div class="row justify-content-center ftco-animate">
          <div class="col-md-8">
            <div class="carousel-testimony owl-carousel ftco-owl">
              <div class="item">
                <div class="testimony-wrap p-4 pb-5">
                  <div class="user-img mb-5" style="background-image: url(images/person_1.jpg)">
                    <span class="quote d-flex align-items-center justify-content-center">
                      <i class="icon-quote-left"></i>
                    </span>
                  </div>
                  <div class="text text-center">
                    <p class="mb-5">"Preva's Student safety and campus security solution is used in all our schools pan India. I am truly delighted with the automation they have achieved and level of support. The unsafe incidents in transport have dramatically reduced, while enhancing level of security within campus. Preva notifications and analytics provides us key info for effective decisions, effectively monitoring schools across India and executive reporting."</p>
                    <span class="position">Prashant Jha, Head, Central Operations, Pearson Schools</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="testimony-wrap p-4 pb-5">
                  <div class="user-img mb-5" style="background-image: url(images/person_2.jpg)">
                    <span class="quote d-flex align-items-center justify-content-center">
                      <i class="icon-quote-left"></i>
                    </span>
                  </div>
                  <div class="text text-center">
                    <p class="mb-5">"LTLIndia, an information providing web portal for Logistics vehicles, selected PREVA TRACKWISE3 GPS Device Cloud platform for its scalability, flexibility, cost effectiveness, versatile web service API's and their depth of expertise in the Track and Trace domain. This was a major value add to launch our operations all over India by giving the capability of tracking our channel partners vehicles which gave a real boost in our operations.”</p>
                    <p class="name">Manjunath B</p>
                    <span class="position">COO, LTLIndia</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="testimony-wrap p-4 pb-5">
                  <div class="user-img mb-5" style="background-image: url(images/person_3.jpg)">
                    <span class="quote d-flex align-items-center justify-content-center">
                      <i class="icon-quote-left"></i>
                    </span>
                  </div>
                  <div class="text text-center">
                    <p class="mb-5">Preva event management platform was chosen for our flagship event at Vision summit 2015. We really liked their module on speed dating that enabled us to match the VC and mentors with our member community.</p>
                    <p class="name"> Venki Kumaran</p>
                    <span class="position">Vice President, Indian Electronics & Semiconductor Association</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="testimony-wrap p-4 pb-5">
                  <div class="user-img mb-5" style="background-image: url(images/person_1.jpg)">
                    <span class="quote d-flex align-items-center justify-content-center">
                      <i class="icon-quote-left"></i>
                    </span>
                  </div>
                  <div class="text text-center">
                    <p class="mb-5">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                    <p class="name">Dennis Green</p>
                    <span class="position">Web Developer</span>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="testimony-wrap p-4 pb-5">
                  <div class="user-img mb-5" style="background-image: url(images/person_1.jpg)">
                    <span class="quote d-flex align-items-center justify-content-center">
                      <i class="icon-quote-left"></i>
                    </span>
                  </div>
                  <div class="text text-center">
                    <p class="mb-5">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                    <p class="name">Dennis Green</p>
                    <span class="position">System Analytics</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>-->

  <!--<section class="ftco-section-parallax">
      <div class="parallax-img d-flex align-items-center">
        <div class="container">
          <div class="row d-flex justify-content-center">
            <div class="col-md-7 text-center heading-section heading-section-white ftco-animate">
              <h2>Subcribe to our Newsletter</h2>
              <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in</p>
              <div class="row d-flex justify-content-center mt-5">
                <div class="col-md-8">
                  <form action="#" class="subscribe-form">
                    <div class="form-group d-flex">
                      <input type="text" class="form-control" placeholder="Enter email address">
                      <input type="submit" value="Subscribe" class="submit px-3">
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>-->

  <!--<section class="ftco-section">
      <div class="container">
        <div class="row justify-content-center mb-5 pb-3">
          <div class="col-md-7 text-center heading-section ftco-animate">
            <h2 class="mb-2">Latest Blog</h2>
            <span class="subheading">Read our blog</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 ftco-animate">
            <div class="blog-entry">
              <a href="blog-single.html" class="block-20" style="background-image: url('images/image_1.jpg');">
              </a>
              <div class="text py-4">
                <div class="meta mb-3">
                  <div><a href="#">August 12, 2018</a></div>
                  <div><a href="#">Admin</a></div>
                  <div><a href="#" class="meta-chat"><span class="icon-chat"></span> 3</a></div>
                </div>
                <h3 class="heading"><a href="#">Even the all-powerful Pointing has no control about the blind texts</a></h3>
              </div>
            </div>
          </div>
          <div class="col-md-4 ftco-animate">
            <div class="blog-entry" data-aos-delay="100">
              <a href="blog-single.html" class="block-20" style="background-image: url('images/image_2.jpg');">
              </a>
              <div class="text py-4">
                <div class="meta mb-3">
                  <div><a href="#">August 12, 2018</a></div>
                  <div><a href="#">Admin</a></div>
                  <div><a href="#" class="meta-chat"><span class="icon-chat"></span> 3</a></div>
                </div>
                <h3 class="heading"><a href="#">Even the all-powerful Pointing has no control about the blind texts</a></h3>
              </div>
            </div>
          </div>
          <div class="col-md-4 ftco-animate">
            <div class="blog-entry" data-aos-delay="200">
              <a href="blog-single.html" class="block-20" style="background-image: url('images/image_3.jpg');">
              </a>
              <div class="text py-4">
                <div class="meta mb-3">
                  <div><a href="#">August 12, 2018</a></div>
                  <div><a href="#">Admin</a></div>
                  <div><a href="#" class="meta-chat"><span class="icon-chat"></span> 3</a></div>
                </div>
                <h3 class="heading"><a href="#">Even the all-powerful Pointing has no control about the blind texts</a></h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>-->
  <br />
  <footer class="ftco-footer ftco-bg-dark ftco-section">
    <div class="container">
      <div class="row mb-5">
        <div class="col-md-4">
          <div class="ftco-footer-widget mb-4">
            <h2 class="ftco-heading-2 text-left">Woodsman</h2>
            <p>
              Woodsman has been established in Dec 2021 with a vision to be
              one of the top 10 IOT Solution Providers in Asia by 2026.
            </p>
          </div>
          <!--  <ul class="ftco-footer-social list-unstyled float-md-left float-lft ">
              <li class="ftco-animate"><a href="#"><span class="icon-twitter"></span></a></li>
              <li class="ftco-animate"><a href="#"><span class="icon-facebook"></span></a></li>
              <li class="ftco-animate"><a href="#"><span class="icon-instagram"></span></a></li>
            </ul>-->
        </div>
        <div class="col-md-4">
          <div class="ftco-footer-widget mb-4 ml-md-5">
            <h2 class="ftco-heading-2 text-left">Quick Links</h2>
            <ul class="list-unstyled text-left">
              
              <li class="list-item">
                <a href="IOT-platform" class="py-2 d-block">Platform</a>
              </li>
              <li class="list-item">
                <a href="solutions" class="py-2 d-block">Solutions</a>
              </li>
              <li class="list-item">
                <a href="coe" class="py-2 dblock">Center Of Excellence</a>
              </li>
              <li class="list-item">
                <a href="career" class="py-2 d-block">Career</a>
              </li>
              <li class="list-item">
                <a href="contact" class="py-2 d-block">Contact</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-md-4">
          <div class="ftco-footer-widget mb-4">
            <h2 class="ftco-heading-2 text-left">Contact Info</h2>
            <div class="block-23 mb-3">
              <ul>
                <li>
                  <span class="icon icon-map-marker"></span><span class="text">Woodsman Smart Retail Solutions LLP<br />
                    Bangalore, India</span>
                </li>
                <li>
                  <a href="#"><span class="icon icon-phone"></span><span class="text">+91 99809 36856</span></a>
                </li>
                <li>
                  <a href="#"><span class="icon icon-envelope"></span><span
                      class="text">info@woodsmanglobal.com</span></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 text-center">
        <p>
          <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
          Copyright &copy;
          <script>
            document.write(new Date().getFullYear());
          </script>
          <a href="" target="_blank">Woodsman Smart Retail Solutions LLP</a>
          <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
        </p>
      </div>
    </div>
  </footer>

  <!-- loader -->
  <!-- <div id="ftco-loader" class="show fullscreen"><svg class="circular" width="48px" height="48px">
            <circle class="path-bg" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke="#eeeeee" />
            <circle class="path" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke-miterlimit="10"
                stroke="#F96D00" />
        </svg></div> -->

  <!-- Modal -->
  <div class="modal fade" id="modalRequest" tabindex="-1" role="dialog" aria-labelledby="modalRequestLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalRequestLabel">Request a Quote</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="form-group">
              <!-- <label for="appointment_name" class="text-black">Full Name</label> -->
              <input type="text" class="form-control" id="appointment_name" placeholder="Full Name" />
            </div>
            <div class="form-group">
              <!-- <label for="appointment_email" class="text-black">Email</label> -->
              <input type="text" class="form-control" id="appointment_email" placeholder="Email" />
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <!-- <label for="appointment_date" class="text-black">Date</label> -->
                  <input type="text" class="form-control" id="appointment_date" placeholder="Date" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <!-- <label for="appointment_time" class="text-black">Time</label> -->
                  <input type="text" class="form-control" id="appointment_time" placeholder="Time" />
                </div>
              </div>
            </div>

            <div class="form-group">
              <!-- <label for="appointment_message" class="text-black">Message</label> -->
              <textarea name="" id="appointment_message" class="form-control" cols="30" rows="10"
                placeholder="Message"></textarea>
            </div>
            <div class="form-group">
              <input type="submit" value="Get a Quote" class="btn btn-primary" />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</body>

</html>