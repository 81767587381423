<!DOCTYPE html>
<html lang="en">

<head>
  <link rel="icon" href="/assets/resources/images/logo/favicon.ico" type="img" sizes="16x16" />
  <title>Event Management | Preva Systems Pvt. Ltd.</title>
  <meta charset="utf-8" />
  <!-- <meta  -->
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
  <meta name="keywords"
    content="platform,internet of things,iot,iot-platform,preva,preva-systems,ios-solutions,industry-solutions,iotapplications,for-corporates,event-management,ROI,corporate-events,academic-events,general-events,
    conventions,one-touch-NFC, RFID,social-meadia,marketing,branding,delegate-experience,customer-engagement,lead-generation,Length-of-stay,Exhibition" />

  <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700" rel="stylesheet" />
  <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css"
    integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossorigin="anonymous" />

  <link rel="stylesheet" href="/assets/resources/css/open-iconic-bootstrap.min.css" />
  <link rel="stylesheet" href="/assets/resources/css/animate.css" />

  <link rel="stylesheet" href="/assets/resources/css/owl.carousel.min.css" />
  <link rel="stylesheet" href="/assets/resources/css/owl.theme.default.min.css" />
  <link rel="stylesheet" href="/assets/resources/css/magnific-popup.css" />

  <link rel="stylesheet" href="/assets/resources/css/aos.css" />

  <link rel="stylesheet" href="/assets/resources/css/ionicons.min.css" />

  <link rel="stylesheet" href="/assets/resources/css/bootstrap-datepicker.css" />
  <link rel="stylesheet" href="/assets/resources/css/jquery.timepicker.css" />

  <link rel="stylesheet" href="/assets/resources/css/flaticon.css" />
  <link rel="stylesheet" href="/assets/resources/css/icomoon.css" />
  <link rel="stylesheet" href="/assets/resources/css/style.css" />
</head>

<body>
  <nav class="navbar navbar-expand-lg navbar-dark ftco_navbar bg-ligh ftco-navbar-light" id="ftco-navbar">
    <div class="container">
      <a class="navbar-brand" href=""><img src="resources/images/logo/LOGO.png" /></a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav"
        aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="oi oi-menu"></span> Menu
      </button>

      <div class="collapse navbar-collapse" id="ftco-nav">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item dropdown">
            <a href="shop" class="nav-link">Shop</a>
          </li>
          <li class="nav-item">
            <a href="IOT-platform" class="nav-link">Platform</a>
          </li>
          <li class="nav-item dropdown" (mouseenter)="onMouseEnter($event)" (mouseleave)="onMouseLeave($event)">
            <a class="nav-link" [attr.aria-expanded]="isDropdownOpen" href="solutions">Solutions</a>
            <ul class="dropdown-menu" [class.show]="isDropdownOpen">
              <li>
                <a href="solutions/asset-management">ASSET MANAGEMENT</a>
              </li>
              <div class="dropdown-divider"></div>
              <li>
                <a href="solutions/event-management">EVENT MANAGEMENT</a>
              </li>
              <div class="dropdown-divider"></div>
              <li>
                <a href="solutions/health-care-management">HEALTHCARE MANAGEMENT</a>
              </li>
              <div class="dropdown-divider"></div>
              <li>
                <a href="solutions/library-management">LIBRARY MANAGEMENT</a>
              </li>

              <div class="dropdown-divider"></div>
              <li>
                <a href="solutions/tracking-management">TRACKING MANAGEMENT</a>
              </li>
            </ul>
          </li>

          <li class="nav-item"><a class="nav-link" href="coe">Coe</a></li>
          <li class="nav-item">
            <a href="career" class="nav-link">Career</a>
          </li>
          <li class="nav-item">
            <a href="contact" class="nav-link">Contact</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!-- END nav -->

  <div class="hero-wrap hero-wrap-2" data-stellar-background-ratio="0.5">
    <!--<div class="overlay"></div>-->
    <div class="container-fluid">
      <div class="row no-gutters d-flex slider-text align-items-center justify-content-center"
        data-scrollax-parent="true">
        <div class="col-md-6 ftco-animate text-center" data-scrollax=" properties: { translateY: '70%' }">
          <p class="breadcrumbs" data-scrollax="properties: { translateY: '30%', opacity: 1.6 }">
            <span class="mr-2"><a href="index.html">home</a></span>
            <span>solutions</span>
          </p>
          <h1 class="mb-3 bread" data-scrollax="properties: { translateY: '30%', opacity: 1.6 }">
            Event Management
          </h1>
        </div>
      </div>
    </div>
  </div>
  <br /><br />

  <div class="container">
    <div class="row">
      <div class="col-sm-6 text-left heading ftco-animate">
        <i class="fas fa-users fa-7x" style="color: #2f89fc"></i>
        <h2 class="mb-2 text-center">For Corporates</h2>
        <p>
          <i class="fa fa-arrow-right" aria-hidden="true" style="color: #2f89fc"></i>
          Simplified one touch NFC / RFID based event management
        </p>
        <p>
          <i class="fa fa-arrow-right" aria-hidden="true" style="color: #2f89fc"></i>
          Social Media integration support or event marketing & brand building
        </p>
        <p>
          <i class="fa fa-arrow-right" aria-hidden="true" style="color: #2f89fc"></i>
          Somprehensive reports to enhance post event delegates experience.
        </p>
        <p>
          <i class="fa fa-arrow-right" aria-hidden="true" style="color: #2f89fc"></i>
          An engaging execu􀆟on to ensure repeat event management.
        </p>
        <p>
          <i class="fa fa-arrow-right" aria-hidden="true" style="color: #2f89fc"></i>
          Event management solu􀆟on for managing corporate event with a clear
          impact ont.
        </p>
        <ol>
          Delegates engagement
        </ol>
        <ol>
          Excitement during events
        </ol>
        <ol>
          Event marketing and updates on social media
        </ol>

        <br />
      </div>

      <div class="col-md-6 text-left heading ftco-animate">
        <i class="fas fa-funnel-dollar fa-7x" style="color: #2f89fc"></i>
        <h3 class="mb-2 text-center">Return On Investments(ROI)</h3>
        <p>
          <i class="fa fa-arrow-right" aria-hidden="true" style="color: #2f89fc"></i>
          Customer Engagement
        </p>
        <p>
          <i class="fa fa-arrow-right" aria-hidden="true" style="color: #2f89fc"></i>
          Brand Recognition
        </p>
        <p>
          <i class="fa fa-arrow-right" aria-hidden="true" style="color: #2f89fc"></i>
          Leads Generation
        </p>
        <p>
          <i class="fa fa-arrow-right" aria-hidden="true" style="color: #2f89fc"></i>
          Customer footall
        </p>
        <p>
          <i class="fa fa-arrow-right" aria-hidden="true" style="color: #2f89fc"></i>
          Social media integration - Global Visibilty
        </p>

        <br /><br />
      </div>

      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-left heading ftco-animate">
            <i class="fas fa-calendar-day fa-7x" style="color: #2f89fc"></i>
            <h2 class="mb-2 text-center">For Event Management Companies</h2>
            <p>
              <i class="fa fa-arrow-right" aria-hidden="true" style="color: #2f89fc"></i>
              Automate / authenticate attendee registration
            </p>
            <p>
              <i class="fa fa-arrow-right" aria-hidden="true" style="color: #2f89fc"></i>
              Length of Stay
            </p>
            <p>
              <i class="fa fa-arrow-right" aria-hidden="true" style="color: #2f89fc"></i>
              Hot Spots
            </p>
            <p>
              <i class="fa fa-arrow-right" aria-hidden="true" style="color: #2f89fc"></i>
              Manage VIP access processing
            </p>
            <p>
              <i class="fa fa-arrow-right" aria-hidden="true" style="color: #2f89fc"></i>
              Attendee Identificatio.
            </p>
            <p>
              <i class="fa fa-arrow-right" aria-hidden="true" style="color: #2f89fc"></i>
              Eliminate counterfeit pass / ticket problems
            </p>
            <p>
              <i class="fa fa-arrow-right" aria-hidden="true" style="color: #2f89fc"></i>
              Capture data for analysis
            </p>

            <br />
          </div>
        </div>
      </div>
    </div>
  </div>

  <section class="ftco-section-parallax">
    <div class="parallax-img d-flex align-items-center">
      <div class="container">
        <div class="row d-flex justify-content-center">
          <div class="col-md-7 text-center heading-section heading-section-white ftco-animate">
            <a class="btn btn-primary px-3 py-2" href="/assets/resources/docs/Eventwise.pdf" download="">Download
              Brochure</a>
            <!-- <div class="row d-flex justify-content-center mt-5">
                      <div class="col-md-8">
                        <form action="#" class="subscribe-form">
                          <div class="form-group d-flex">
                            <input type="text" class="form-control" placeholder="Enter email address">
                            <input type="submit" value="Subscribe" class="submit px-3">
                          </div>
                        </form>
                      </div>
                    </div>-->
          </div>
        </div>
      </div>
    </div>
  </section>

  <br />
  <div class="container">
    <div class="row">
      <div class="col-md-4 d-flex align-self-stretch ftco-animate">
        <div class="media block-6 services d-block text-left">
          <div class="d-flex justify-content-center">
            <div class="icon">
              <img src="/assets/resources/images/icons/coprate-events.png" alt="" />
            </div>
          </div>
          <div class="media-body p-2 mt-3">
            <h3 class="heading text-center">Corporate Events</h3>
            <p>Conferences and Seminars</p>
            <p>Business development</p>
            <p>Product launches</p>
            <p>Executive visits</p>
            <p>Lead generation</p>
            <p>Residential conferences</p>
            <p>Briefing sessions</p>
            <p>Road shows</p>
            <p>Channel events</p>
            <p>Awards functions</p>
            <p>Industry association events</p>
          </div>
        </div>
      </div>
      <div class="col-md-4 d-flex align-self-stretch ftco-animate">
        <div class="media block-6 services d-block text-left">
          <div class="d-flex justify-content-center">
            <div class="icon">
              <img src="/assets/resources/images/icons/academic-events.png" alt="" />
            </div>
          </div>
          <div class="media-body p-2 mt-3">
            <h3 class="heading text-center">Academic Events</h3>
            <p>Conferences and Seminars</p>
            <p>Award functions</p>
            <p>Annual Gatherings</p>
            <p>Excursions</p>
            <p>Children’s / Teacher’s Day</p>
            <p>Parent’s Day</p>
          </div>
        </div>
      </div>
      <div class="col-md-4 d-flex align-self-stretch ftco-animate">
        <div class="media block-6 services d-block text-left">
          <div class="d-flex justify-content-center">
            <div class="icon">
              <img src="/assets/resources/images/icons/genral-events.png" alt="" />
            </div>
          </div>
          <div class="media-body p-2 mt-3">
            <h3 class="heading text-center">General Events</h3>
            <p>Conference and seminars</p>
            <p>Exhibitions</p>
            <p>Conventions</p>
            <p>Marriages / Receptions</p>
            <p>Admissions / Recruitment</p>

            <p>Birthday / Valentine / New Year</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->

  <!-- <div class="container-wrap mt-5">
      	<div class="row d-flex no-gutters">
      		<div class="col-md-6 img ftco-animate" style="background-image: url(images/about.jpg);">
      		</div>
      		<div class="col-md-6 d-flex">
      			<div class="services-wrap">
      				<div class="heading-section mb-5 ftco-animate">
		            <h2 class="mb-2">Our Service Keeps you Happy</h2>
		            <span class="subheading">Search Engine &amp; Social Media Optimization Experts</span>
		          </div>
      				<div class="list-services d-flex ftco-animate">
      					<div class="icon d-flex justify-content-center align-items-center">
      						<span class="icon-pencil"></span>
      					</div>
      					<div class="text">
	      					<h3>Logo Branding</h3>
	      					<p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
      					</div>
      				</div>
      				<div class="list-services d-flex ftco-animate">
      					<div class="icon d-flex justify-content-center align-items-center">
      						<span class="icon-web"></span>
      					</div>
      					<div class="text">
	      					<h3>Development</h3>
	      					<p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
      					</div>
      				</div>
      				<div class="list-services d-flex ftco-animate">
      					<div class="icon d-flex justify-content-center align-items-center">
      						<span class="icon-pie-chart"></span>
      					</div>
      					<div class="text">
	      					<h3>Online Marketing</h3>
	      					<p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
      					</div>
      				</div>
      			</div>
      		</div>
      	</div>-->
  <!-- </div> -->
  <!-- </div> -->

  <!--  <section class="ftco-section-parallax">
      <div class="parallax-img d-flex align-items-center">
        <div class="container">
          <div class="row d-flex justify-content-center">
            <div class="col-md-7 text-center heading-section heading-section-white ftco-animate">
              <h2>For Demo</h2>
              <p>Contact Preva Systems</p>
             <div class="row d-flex justify-content-center mt-5">
                <div class="col-md-8">
                  <form action="#" class="subscribe-form">
                    <div class="form-group d-flex">
                      <input type="text" class="form-control" placeholder="Enter email address">
                      <input type="submit" value="Subscribe" class="submit px-3">
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>-->

    <footer class="ftco-footer ftco-bg-dark ftco-section">
      <div class="container">
        <div class="row mb-5">
          <div class="col-md-4">
            <div class="ftco-footer-widget mb-4">
              <h2 class="ftco-heading-2 text-left">Woodsman</h2>
              <p>
                Woodsman has been established in Dec 2021 with a vision to be
                one of the top 10 IOT Solution Providers in Asia by 2026.
              </p>
            </div>
            <!--  <ul class="ftco-footer-social list-unstyled float-md-left float-lft ">
                <li class="ftco-animate"><a href="#"><span class="icon-twitter"></span></a></li>
                <li class="ftco-animate"><a href="#"><span class="icon-facebook"></span></a></li>
                <li class="ftco-animate"><a href="#"><span class="icon-instagram"></span></a></li>
              </ul>-->
          </div>
          <div class="col-md-4">
            <div class="ftco-footer-widget mb-4 ml-md-5">
              <h2 class="ftco-heading-2 text-left">Quick Links</h2>
              <ul class="list-unstyled text-left">
                
                <li class="list-item">
                  <a href="IOT-platform" class="py-2 d-block">Platform</a>
                </li>
                <li class="list-item">
                  <a href="solutions" class="py-2 d-block">Solutions</a>
                </li>
                <li class="list-item">
                  <a href="coe" class="py-2 dblock">Center Of Excellence</a>
                </li>
                <li class="list-item">
                  <a href="career" class="py-2 d-block">Career</a>
                </li>
                <li class="list-item">
                  <a href="contact" class="py-2 d-block">Contact</a>
                </li>
              </ul>
            </div>
          </div>
  
          <div class="col-md-4">
            <div class="ftco-footer-widget mb-4">
              <h2 class="ftco-heading-2 text-left">Contact Info</h2>
              <div class="block-23 mb-3">
                <ul>
                  <li>
                    <span class="icon icon-map-marker"></span><span class="text">Woodsman Smart Retail Solutions LLP<br />
                      Bangalore, India</span>
                  </li>
                  <li>
                    <a href="#"><span class="icon icon-phone"></span><span class="text">+91 99809 36856</span></a>
                  </li>
                  <li>
                    <a href="#"><span class="icon icon-envelope"></span><span
                        class="text">info@woodsmanglobal.com</span></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="row">
        <div class="col-md-12 text-center">
          <p>
            <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
            Copyright &copy;
            <script>
              document.write(new Date().getFullYear());
            </script>
            <a href="" target="_blank">Woodsman Smart Retail Solutions LLP</a>
            <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
          </p>
        </div>
      </div>
    </footer>

  <!-- loader -->
  <!-- <div id="ftco-loader" class="show fullscreen"><svg class="circular" width="48px" height="48px"><circle class="path-bg" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke="#eeeeee"/><circle class="path" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke-miterlimit="10" stroke="#F96D00"/></svg></div> -->

  <!-- Modal -->
  <div class="modal fade" id="modalRequest" tabindex="-1" role="dialog" aria-labelledby="modalRequestLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalRequestLabel">Request a Quote</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="form-group">
              <!-- <label for="appointment_name" class="text-black">Full Name</label> -->
              <input type="text" class="form-control" id="appointment_name" placeholder="Full Name" />
            </div>
            <div class="form-group">
              <!-- <label for="appointment_email" class="text-black">Email</label> -->
              <input type="text" class="form-control" id="appointment_email" placeholder="Email" />
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <!-- <label for="appointment_date" class="text-black">Date</label> -->
                  <input type="text" class="form-control" id="appointment_date" placeholder="Date" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <!-- <label for="appointment_time" class="text-black">Time</label> -->
                  <input type="text" class="form-control" id="appointment_time" placeholder="Time" />
                </div>
              </div>
            </div>

            <div class="form-group">
              <!-- <label for="appointment_message" class="text-black">Message</label> -->
              <textarea name="" id="appointment_message" class="form-control" cols="30" rows="10"
                placeholder="Message"></textarea>
            </div>
            <div class="form-group">
              <input type="submit" value="Get a Quote" class="btn btn-primary" />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <script src="/assets/resources/js/jquery.min.js"></script>
  <script src="/assets/resources/js/jquery-migrate-3.0.1.min.js"></script>
  <script src="/assets/resources/js/popper.min.js"></script>
  <script src="/assets/resources/js/bootstrap.min.js"></script>
  <script src="/assets/resources/js/jquery.easing.1.3.js"></script>
  <script src="/assets/resources/js/jquery.waypoints.min.js"></script>
  <script src="/assets/resources/js/jquery.stellar.min.js"></script>
  <script src="/assets/resources/js/owl.carousel.min.js"></script>
  <script src="/assets/resources/js/jquery.magnific-popup.min.js"></script>
  <script src="/assets/resources/js/aos.js"></script>
  <script src="/assets/resources/js/jquery.animateNumber.min.js"></script>
  <script src="/assets/resources/js/bootstrap-datepicker.js"></script>
  <script src="/assets/resources/js/jquery.timepicker.min.js"></script>
  <script src="/assets/resources/js/scrollax.min.js"></script>
  <script
    src="/assets/resources/https://maps.googleapis.com/maps/api/js?key=AIzaSyBVWaKrjvy3MaE7SQ74_uJiULgl1JY0H2s&sensor=false"></script>
  <script src="/assets/resources/js/google-map.js"></script>
  <script src="/assets/resources/js/main.js"></script>
</body>

</html>