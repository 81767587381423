<div style="height: 60px;">

</div>
<p>profile works!</p>
<div class="container mt-5" fxLayout="row" fxLayoutAlign="center center">
    <div fxFlex="80">
      <h2 style="font-size:3rem;">PROFILE</h2>
  
      <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
        <!-- Username and Account Type -->
        <div fxLayout="row" fxLayoutGap="16px">
          <mat-form-field fxFlex="50">
            <mat-label>Username</mat-label>
            <input matInput formControlName="username" placeholder="User Name" readonly />
          </mat-form-field>
  
          <mat-form-field fxFlex="50">
            <mat-label>Account Type</mat-label>
            <input matInput formControlName="con_type" placeholder="Account Type" />
          </mat-form-field>
        </div>
  
        <!-- First and Last Name -->
        <div fxLayout="row" fxLayoutGap="16px">
          <mat-form-field fxFlex="50">
            <mat-label>First Name</mat-label>
            <input matInput formControlName="first_name" placeholder="First Name" />
          </mat-form-field>
  
          <mat-form-field fxFlex="50">
            <mat-label>Last Name</mat-label>
            <input matInput formControlName="last_name" placeholder="Last Name" />
          </mat-form-field>
        </div>
  
        <!-- Mobile Number and Email -->
        <div fxLayout="row" fxLayoutGap="16px">
          <mat-form-field fxFlex="50">
            <mat-label>Phone Number</mat-label>
            <input matInput formControlName="mobile_no" placeholder="Phone Number" />
          </mat-form-field>
  
          <mat-form-field fxFlex="50">
            <mat-label>Email Address</mat-label>
            <input matInput type="email" formControlName="email" placeholder="Email Address" />
          </mat-form-field>
        </div>
  
        <!-- Address -->
        <div fxLayout="row" fxLayoutGap="16px">
          <mat-form-field fxFlex="100">
            <mat-label>Address</mat-label>
            <input matInput formControlName="adress" placeholder="Address" />
          </mat-form-field>
        </div>
  
        <!-- City and Country -->
        <div fxLayout="row" fxLayoutGap="16px">
          <mat-form-field fxFlex="50">
            <mat-label>City</mat-label>
            <input matInput formControlName="city" placeholder="City" />
          </mat-form-field>
  
          <mat-form-field fxFlex="50">
            <mat-label>Country</mat-label>
            <input matInput formControlName="country" placeholder="Country" />
          </mat-form-field>
        </div>
  
        <!-- About -->
        <div fxLayout="row">
          <mat-form-field fxFlex="100">
            <mat-label>About</mat-label>
            <textarea matInput formControlName="about" placeholder="About"></textarea>
          </mat-form-field>
        </div>
  
        <!-- Buttons (Desktop and Mobile) -->
        <div fxLayout="row" fxLayoutAlign="center center" class="py-4">
          <!-- For desktop -->
          <button mat-raised-button color="primary" type="submit" style="font-size: 1.2rem; margin-right: 16px;">
            Save
          </button>
          <button mat-raised-button color="warn" type="button" (click)="onCancel()" style="font-size: 1.2rem;">
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
  