import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pindex',
  templateUrl: './pindex.component.html',
  styleUrls: ['./pindex.component.scss']
})
export class PindexComponent implements OnInit {
  isDropdownOpen: boolean = false;
  // List of images to be displayed in the slider
  images: string[] = [
    '/assets/resources/images/logo/iot2.png',
    '/assets/resources/images/logo/cloudofthingsv2.png',
    '/assets/resources/images/logo/IoT_png.png'
  ];

  currentIndex: number = 0;
  slideInterval: any;
  constructor() { }

  ngOnInit(): void {
    this.startAutoSlide();
  }


  onMouseEnter(event: Event): void {
    const element = event.target as HTMLElement;
    this.isDropdownOpen = true;
    element.setAttribute('aria-expanded', 'true');
  }

  onMouseLeave(event: Event): void {
    const element = event.target as HTMLElement;
    this.isDropdownOpen = false;
    element.setAttribute('aria-expanded', 'false');
  }
    // Move to the next slide
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
    }
  
    // Move to the previous slide
    prevSlide() {
      this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length;
    }
    startAutoSlide() {
      this.slideInterval = setInterval(() => {
        this.nextSlide();
      }, 3000);
    }
  
    // Stop the auto-slide on hover
    stopAutoSlide() {
      clearInterval(this.slideInterval);
    }
  
    // Restart auto-slide when mouse leaves the slider area
    restartAutoSlide() {
      this.startAutoSlide();
    }
}
