import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EcomService } from 'src/app/ecom.service';

@Component({
  selector: 'app-s-corporate',
  templateUrl: './s-corporate.component.html',
  styleUrls: ['./s-corporate.component.scss']
})
export class SCorporateComponent implements OnInit {


  signupForm: FormGroup;
  logoUrl: string = ''; // Assign the actual logo URL here
  errorMessage: string = '';
  successMessage: string = '';
  index_data: any;

  constructor(private fb: FormBuilder,private es:EcomService) {
    this.signupForm = this.fb.group({
      username: ['', Validators.required],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mobile_no: ['', Validators.required],
      con_type: ['CONSIGNEE', Validators.required],
      password1: ['', Validators.required],
      password2: ['', Validators.required],
    });
  }
  ngOnInit(): void {
    this.index_data = JSON.parse(localStorage.getItem("index_data"));
  }

  onSubmit() {
    if (this.signupForm.valid) {
      const formValues = this.signupForm.value;
      if (formValues.password1 !== formValues.password2) {
        this.errorMessage = 'Passwords do not match!';
        return;
      }

      // Perform signup logic here
      console.log('Form Submitted:', formValues);
      this.es.login(formValues).subscribe(
        data=>{
          console.log(
            data
          )
          this.successMessage = "done"
        },
        error=>{
          console.log(
            this.errorMessage = error.error
          )
        }
      );

      // For demonstration, set a success message
      this.successMessage = 'Signup successful!';
    }
  }
}