import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { EcomService } from './ecom.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  
  constructor(private titleService: Title,private es:EcomService) {}

  ngOnInit(): void {
    this.es.index().subscribe(
      data=>{
        // this.tabs = []
        console.log(data)
        localStorage.setItem("index_data",JSON.stringify(data));
        this.setTitle(data['org_name']);
      }
    )
    
  }

  setTitle(newTitle: string): void {
    this.titleService.setTitle(newTitle);
  }
}
