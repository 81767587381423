
  import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
  import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Ip_address } from 'src/ipaddress';
  @Injectable({
    providedIn: 'root'
  })
  export class EcomService {
  
  
    // console.log(ip_address)
  
  
    private baseUrl = Ip_address.ip_address+':2091';
    private baseUrl_1 = Ip_address.ip_address_1+':2082';
  
    constructor(private http: HttpClient) { 
      console.log(Ip_address.ip_address)
     }

  index() {
    return this.http.get(`${this.baseUrl}/api_index`,);
  }

  list_product(item: string, label: string) {
    // const params = new HttpParams()
    //   .set('item', item)
    //   .set('label', label);
  
    return this.http.get(`${this.baseUrl}/api_list_product?item=${item}&label=${label}`);
  }
  list_product_details(org_name,item_name,item_type,item_subtype) {
    // const params = new HttpParams()
    //   .set('item', item)
    //   .set('label', label);
  
    return this.http.get(`${this.baseUrl}/list_product_details?org_name=${org_name}&item_name=${item_name}&item_type=${item_type}&item_subtype=${item_subtype}`);
  }

  login(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl_1}/login/`,data);
  }
  sign_up(data: any): Observable<any> {
    return this.http.post(`${this.baseUrl_1}/sign_up/`,data);
  }
}
