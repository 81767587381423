export class Ip_address {
  // Get the current hostname from the browser URL
  // Define a default hostname to use if on localhost
  static default_hostname: string = "https://woodsmanglobal.com";
  static ip_address_1: string = "https://saasapps.in";

  // Method to check if the hostname is localhost and adjust accordingly
  static getAdjustedHostname(): string {
    // Check if the hostname is localhost or its variations
    if (
      window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1'
    ) {
      // If on localhost, use the default hostname
      return Ip_address.default_hostname;
    }
    // Otherwise, return the specific IP address or hostname
    return "https://"+window.location.hostname;
  }

  // Assign the adjusted hostname
  static ip_address: string = Ip_address.getAdjustedHostname();
}
