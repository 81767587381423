import { Component, OnInit, ViewChild } from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import {MatTabsModule} from '@angular/material/tabs';
import {MatListModule} from '@angular/material/list';
import { CommonModule } from '@angular/common';
import {MatMenuModule} from '@angular/material/menu';
import { MaterialModule } from '../material/material.module';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { EcomService } from '../ecom.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  tabs = [
    { label: 'WOMAN', items: [  '/// NEW',
      'DAY & NIGHT EVENTS',
      'SUMMER WHITES',
      'DRESSES',
      'TOPS | BODYSUITS',
      'T-SHIRTS',
      'SHIRTS',
      'SKIRTS',
      'SHORTS | SKORTS',
      'TROUSERS',
      'JEANS',
      'WAISTCOATS',
      'KNITWEAR',
      'CARDIGANS | SWEATERS',
      'JACKETS | BLAZERS',
      'SHOES',
      'BAGS',
      'ACCESSORIES | JEWELLERY',
      'SWIMWEAR',
      'PERFUMES',
      'MAKEUP',
      'CO-ORD SETS',
      'LINEN',
      'BASICS',
      'SPECIAL PRICES',] },
    { label: 'MAN', items: ['NEW',
  'DAY & NIGHT EVENTS',
  'SUMMER WHITES',
  'DRESSES',
  'TOPS | BODYSUITS',
  'T-SHIRTS',
  'SHIRTS',
  'SKIRTS',
  'SHORTS | SKORTS',
  'TROUSERS',
  'JEANS',
  'WAISTCOATS',
  'KNITWEAR',
  'CARDIGANS | SWEATERS',
  'JACKETS | BLAZERS',
  'SHOES',
  'BAGS',
  'ACCESSORIES | JEWELLERY',
  'SWIMWEAR',
  'PERFUMES',
  'MAKEUP',
  'CO-ORD SETS',
  'LINEN',
  'BASICS',
  'SPECIAL PRICES',
  'SALE',
  'GIFT CARD',
  'JOIN LIFE',
  '+ INFO'] },
    { label: 'KIDS', items: [  "GIRL",
      "1½ - 6 YEARS",
      "6-14 YEARS",
      "BOY",
      "1½ - 6 YEARS",
      "6-14 YEARS",
      "BABY",
      "0 - 6 MONTHS",
      "6 - 18 MONTHS",
      "ACCESSORIES | SHOES | 0 - 14 YEARS",
      "BACK TO SCHOOL",
      "TIMELESZ",
      "SALE",
      "JOIN LIFE",
      "CAREERS",
      "+ INFO"] },
    { label: 'BEAUTY', items: [  "WOMAN",
      "PERFUMES",
      "MAKEUP",
      "MAN",
      "KIDS",
      "JOIN LIFE",
      "+ INFO"] }
  ];

  showBackButton: boolean = false;
  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('sidenav2') sidenav2: MatSidenav;
  org_name: any;
  currentUser: any;
  isLoggedIn: any;

  constructor(private router: Router,private es:EcomService) {
    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     this.showBackButton = event.url === '/login/individual' || event.url === '/list_product' || event.url === '/signup/individual' || event.url === '/login/corporate' || event.url === '/signup/corporate';
    //   }
    // });
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      // Extract the path without query parameters
      const path = event.urlAfterRedirects.split('?')[0];
      this.showBackButton = path === '/login/individual' ||
                            path === '/list_product' ||
                            path === '/signup/individual' ||
                            path === '/login/corporate' ||
                            path === '/product_details' ||
                            path === '/cart' ||
                            path === '/signup/corporate';
    });
  }

  ngOnInit(): void {
    this.isLoggedIn = JSON.parse(localStorage.getItem('currentUser'))

    console.log(
      this.isLoggedIn
    )

    this.es.index().subscribe(
      data=>{
        this.tabs = []
        console.log(data)
        this.org_name = data['org_name'].toUpperCase();
        
        // { label: 'WOMAN', items: []}
        data['list_item_row_data'].forEach(element => {
          const items = [];
          data['list_item_sub_row'].forEach(sub_element => {
            
            if(sub_element['ItemType_Name']==element['ItemType_Name']){
              // console.log(sub_element)
              items.push(sub_element['ItemSubType_Name'])
            }

          })
          this.tabs.push(
            { label: element['ItemType_Name'], items: items}
          )
          
        });
        


      }
    )
   }

  goBack(): void {
    history.back();
  }
  onToggleChange(event: any) {
    console.log(event.value); // Log the selected tab label
  }
  onClick(data: any) {
    // Handle click event with data
    console.log('Span clicked with data:', data);
    this.router.navigate(['/list_product'], { queryParams: data });
    if (this.sidenav) {
      this.sidenav.close();
    }
    if (this.sidenav2) {
      this.sidenav2.close();
    }
  }
  navigateToProfile(){
    this.router.navigate(['/profile'])
  }
  logout(){

    localStorage.removeItem("currentUser");
    this.router.navigate([""]);
    this.ngOnInit();
    
  }
}
