import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-health-care-management',
  templateUrl: './health-care-management.component.html',
  styleUrls: ['./health-care-management.component.scss']
})
export class HealthCareManagementComponent implements OnInit {

  isDropdownOpen: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }


  onMouseEnter(event: Event): void {
    const element = event.target as HTMLElement;
    this.isDropdownOpen = true;
    element.setAttribute('aria-expanded', 'true');
  }

  onMouseLeave(event: Event): void {
    const element = event.target as HTMLElement;
    this.isDropdownOpen = false;
    element.setAttribute('aria-expanded', 'false');
  }

}
