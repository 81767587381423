import {  OnInit } from '@angular/core';
import { Component, HostListener } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-main-content',
  templateUrl: './main-content.component.html',
  styleUrls: ['./main-content.component.scss']
})
export class MainContentComponent implements OnInit {

  images = [
    'assets/images/Amazon creatives-10.jpg',
    'assets/images/Amazon creatives-20.jpg',
    'assets/images/Amazon creatives-06.jpg'
  ];
  images_1 = [
    'assets/images/solutions-for-education.png',
    'assets/images/solutions-for-health.png',
    'assets/images/solutions-for-industries.png',
    'assets/images/solutions-for-transport.png'
  ];
  mo_images = [
    'assets/images/Amazon creatives-10.jpg',
    'assets/images/Amazon creatives-20.jpg',
    'assets/images/Amazon creatives-06.jpg'

  ];
  mo_images_1 = [
    'assets/images/solutions-for-education.png',
    'assets/images/solutions-for-health.png',
    'assets/images/solutions-for-industries.png',
    'assets/images/solutions-for-transport.png'

  ];

  currentIndex = 0;
  cycles = 3;  // Number of cycles from top to bottom and bottom to top
  constructor() { }

  ngOnInit(): void {
    console.log(window.location.hostname)
    if (window.location.hostname === 'woodsmanglobal.com'){
      this.mo_images = this.mo_images_1;
      this.images = this.images_1;

    }
  }
  nextImage() {
    this.currentIndex = (this.currentIndex + 1) % this.images.length;
  }

  previousImage() {
    this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length;
  }
}
