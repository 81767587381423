<div style="height: 60px;">

</div>


<div fxLayout="column" fxLayoutAlign="center center" class="container">
  <mat-card class="login-container">
    <div class="text-center py-1">
      <p>Welcome to</p>
      <h1>
        {{this.index_data.org_name.toUpperCase()}}

      </h1>
      <!-- <div style="height: 130px;">
        <img alt="Center Logo" style="width: 100%; height: 100%; object-fit: contain; object-position: center center;" src="https://saasapps.in:2082/media/logo1_Ln95AOw.jpg">
      </div> -->
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%;">
      <h2>Corporate User Login</h2>

    </div>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Please Enter Your E-mail</mat-label>
        <input matInput formControlName="username" placeholder="username@email.com">
        <mat-error *ngIf="loginForm.get('username').hasError('required')">Email is required</mat-error>
        <mat-error *ngIf="loginForm.get('username').hasError('email')">Enter a valid email</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Password</mat-label>
        <input matInput [type]="'password'" formControlName="password" placeholder="Enter password">
        <mat-error *ngIf="loginForm.get('password').hasError('required')">Password is required</mat-error>
        <a mat-button routerLink="/forget_password" style="position: absolute; top: 50%; transform: translateY(-50%); right: 0; color: #936917; font-weight: bold;">
          Forgot Password?
        </a>
      </mat-form-field>

      <div class="text-center py-3">
        <p class="roboto-font" style="font-size: 14px;">
          Please agree to
          <span class="font-weight-bold" style="color: #936917;"> Terms of Service </span> &
          <span class="font-weight-bold" style="color: #936917;"> Privacy Policy </span>
        </p>
      </div>

      <div fxLayout="row" fxLayoutAlign="center center" class="full-width" fxLayoutGap="8px">
        <button mat-raised-button color="primary" type="submit" style="background-color: white; color: black;width: 100%;">Login</button>
      </div>
    </form>
      <div fxLayout="column" fxLayoutAlign="center center" style="margin-top: 50px;">
        <h2>Need an account?</h2>
  
          <button mat-raised-button color="primary" style="width: 100%;background-color: white;color: black;" [routerLink]="'/signup/corporate'">Register</button>
  
      </div>
  </mat-card>
</div>
