<div class="image-slider" fxLayout="row" fxHide.xs>

    <img [src]="images[currentIndex]" alt="Image Slider">
    <button class="prev-button" (click)="previousImage()"><mat-icon>arrow_back_ios</mat-icon></button>
    <button class="next-button" (click)="nextImage()"><mat-icon>arrow_forward_ios</mat-icon></button>
  </div>
  
  <div class="image-slider-mo">
  
    <div *ngFor="let image of mo_images; let i = index">
      <img [src]="image">
    </div>
  
  
  </div>